<template>
    <page-title
        icon="bi-pencil-square"
        title="製品情報編集"
    >
        <button-back
            :to="{name: 'ProductGroupList'}"
            text="製品一覧へ"
        ></button-back>
    </page-title>

    <inline-loader v-if="loading"></inline-loader>
    <template v-else>
        <section class="section">
            <div class="d-flex gap-1">
                <div class="col-16">
                    <h2>
                        <span class="badge bg-primary">{{ product_group_revision.product_group_code }}</span> {{ product_group_revision.product_group_name }}
                    </h2>
                    <div>
                        <small class="fw-bold me-3">最終公開日:</small>
                        <small class="me-3">{{ product_group_revision.latest_published_date }}</small>
                    </div>
                    <div>
                        <small class="fw-bold me-3">最終更新日:</small>
                        <small class="me-4">{{ product_group_revision.updated_date }}</small>
                        <small class="fw-bold me-3">最終更新者:</small>
                        <small>{{ product_group_revision.updated_user_name }}</small>
                    </div>
                    <router-link :to="{name: 'ProductGroupHistory', params: {id: product_group_id}}">
                        変更履歴へ <i class="bi bi-reply rotate"></i>
                    </router-link>
                </div>
                <div class="col-4 card bg-success text-white text-center link-parent">
                    <div class="card-header p-1">公開中</div>
                    <div class="card-body p-1">
                        <div>
                            <small>リビジョン</small>
                        </div>
                        <div class="fs-1">{{ product_group_revision.published_revision }}</div>
                    </div>
                    <div class="link-child" v-if="product_group_revision.published_status !== PublishedStatus.DRAFT">
                        <button-go-view
                            :to="{name: 'ProductGroupView', params: {id: product_group_id}}"
                            text=''
                            icon='bi-reply'
                            class="small-icon rotate"
                        ></button-go-view>
                    </div>
                </div>
                <div class="col-4 card bg-danger text-white text-center link-parent">
                    <div class="card-header p-1">編集中</div>
                    <div class="card-body p-1">
                        <div>
                            <small>リビジョン</small>
                        </div>
                        <div class="fs-1">{{ product_group_revision.revision }}</div>
                    </div>
                    <div class="link-child">
                        <button-go-update
                            v-if="
                                'revision' in product_group_revision
                                && product_group_revision.revision
                                && product_group_revision.revision !== product_group_revision.published_revision
                            "
                            :to="{name: 'ProductGroupRevisionView', params: {id: product_group_id, revision: product_group_revision.revision}}"
                            text=''
                            icon='bi-reply'
                            class="small-icon rotate"
                        ></button-go-update>
                    </div>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="row align-items-end mb-3">
                <div class="form-group col-md-6">
                    <label>公開区分</label>
                    <form-select
                        v-model="product_group_revision.public_type"
                        :options="public_types"
                        :required="true"
                        :disabled="!$store.getters['auth/canEditBasic']()"
                        empty_option="-- 選択 --"
                    ></form-select>
                </div>
                <div class="form-group col-md-6">
                    <label>ステータス</label>
                    <form-select
                        v-model="product_group_revision.availability"
                        :options="availabilities"
                        :required="true"
                        :disabled="!$store.getters['auth/canEditBasic']()"
                        empty_option="-- 選択 --"
                    ></form-select>
                </div>
                <div class="form-group col-md-6">
                    <label>取引停止日</label>
                    <form-input-date
                        v-model="product_group_revision.pending_date"
                        :disabled="!$store.getters['auth/canEditBasic']()"
                    ></form-input-date>
                </div>
                <div class="form-group col-md-6">
                    <button-exec
                        text="規格変更履歴"
                        @click="openWorkflowModal"
                    >
                    <template v-if="product_group_revision.workflows.find(workflow => {
                        return workflow.approved_status === ApprovedStatus.PENDING
                    })">
                        <span class="badge bg-warning">未完了あり</span>
                    </template>
                    </button-exec>
                </div>
            </div>
        </section>

        <section class="section">
            <h2 class="section-title">製品グループ情報</h2>
            <div class="row align-items-end mb-3">
                <div class="form-group col-md-6">
                    <label>製品グループコード</label>
                    <form-input
                        v-model="product_group_revision.product_group_code"
                        :required="true"
                        maxlength="191"
                        pattern="^[a-zA-Z0-9!-/:-@¥[-`{-~]*$"
                        :disabled="!$store.getters['auth/canEditBasic']()"
                    ></form-input>
                </div>
                <div class="form-group col-md-6">
                    <label>製品グループ名</label>
                    <form-input
                        v-model="product_group_revision.product_group_name"
                        :required="true"
                        maxlength="191"
                        :disabled="!$store.getters['auth/canEditBasic']()"
                    ></form-input>
                </div>
                <div class="form-group col-md-6">
                    <label>製品グループ名 (かな)</label>
                    <form-input
                        v-model="product_group_revision.product_group_name_kana"
                        maxlength="191"
                        :disabled="!$store.getters['auth/canEditBasic']()"
                    ></form-input>
                </div>
                <div class="form-group col-md-6">
                    <label>製品グループ名 (英)</label>
                    <form-input
                        v-model="product_group_revision.product_group_name_en"
                        maxlength="191"
                        :disabled="!$store.getters['auth/canEditBasic']()"
                    ></form-input>
                </div>
            </div>
            <div class="row align-items-end mb-3">
                <div class="form-group col-md-6">
                    <label>オプション名</label>
                    <form-input
                        v-model="product_group_revision.option_name"
                        maxlength="191"
                        :disabled="!$store.getters['auth/canEditBasic']()"
                    ></form-input>
                </div>
                <div class="form-group col-md-6">
                    <label>オプション名 (英)</label>
                    <form-input
                        v-model="product_group_revision.option_name_en"
                        maxlength="191"
                        :disabled="!$store.getters['auth/canEditBasic']()"
                    ></form-input>
                </div>
                <div class="form-group col-md-6">
                    <label>仕入区分</label>
                    <form-select
                        v-model="product_group_revision.supply_type"
                        :options="supply_types"
                        empty_option="-- 選択 --"
                        :disabled="!$store.getters['auth/canEditBasic']()"
                    ></form-select>
                </div>
            </div>
            <div class="row align-items-end mb-3">
                <div class="form-group col-md-6">
                    <label>項目1</label>
                    <form-input
                        v-model="product_group_revision.field_1"
                        maxlength="191"
                        :disabled="!$store.getters['auth/canEditBasic']()"
                    ></form-input>
                </div>
                <div class="form-group col-md-6">
                    <label>項目2</label>
                    <form-input
                        v-model="product_group_revision.field_2"
                        maxlength="191"
                        :disabled="!$store.getters['auth/canEditBasic']()"
                    ></form-input>
                </div>
                <div class="form-group col-md-6">
                    <label>項目3</label>
                    <form-input
                        v-model="product_group_revision.field_3"
                        maxlength="191"
                        :disabled="!$store.getters['auth/canEditBasic']()"
                    ></form-input>
                </div>
            </div>
            <div class="row align-items-end mb-3">
                <div class="form-group col-md-24">
                    <label>説明</label>
                    <form-textarea
                        v-model="product_group_revision.description"
                        row="'2'"
                        :disabled="!$store.getters['auth/canEditCatalog']()"
                    ></form-textarea>
                </div>
            </div>
            <div class="row align-items-end mb-3">
                <div class="form-group col-md-24">
                    <label>説明 (英)</label>
                    <form-textarea
                        v-model="product_group_revision.description_en"
                        row="'2'"
                        :disabled="!$store.getters['auth/canEditCatalog']()"
                    ></form-textarea>
                </div>
            </div>
            <div class="row align-items-end mb-3">
                <div class="form-group col-md-24">
                    <label>社内備考</label>
                    <form-textarea
                        v-model="product_group_revision.private_note"
                        row="'2'"
                    ></form-textarea>
                </div>
            </div>

            <h4 class="mt-5">画像</h4>
            <div class="mb-3">
                <form-upload-multiple
                    v-model="product_group_revision.images"
                    :upload_dir="UploadService.PRODUCT_GROUP_IMAGE"
                    :is_editable="$store.getters['auth/canEditCatalog']()"
                ></form-upload-multiple>
            </div>
            <h4 class="mt-4">カタログ</h4>
            <div class="row align-items-end mb-3">
                <div class="form-group col-md-6">
                    <form-checkbox
                        v-model="product_group_revision.is_on_catalog"
                        option_label="カタログに掲載"
                        class="form-control"
                        :disabled="!$store.getters['auth/canEditCatalog']()"
                    ></form-checkbox>
                </div>
                <div class="form-group col-md-6">
                    <form-checkbox
                        v-model="product_group_revision.is_price_on_catalog"
                        option_label="カタログに金額掲載"
                        class="form-control"
                        :disabled="!$store.getters['auth/canEditCatalog']()"
                    ></form-checkbox>
                </div>
            </div>
            <template v-for="catalog_page, index in product_group_revision.catalog_pages" :key="catalog_page">
                <div class="row align-items-end mb-3">
                    <div class="col-md-6 pe-1">
                        <label>カタログ</label>
                        <form-select
                            v-model="catalog_page.catalog.catalog_id"
                            :options="select_catalogs"
                            empty_option="-- 選択 --"
                            required="required"
                            :disabled="!$store.getters['auth/canEditCatalog']()"
                        ></form-select>
                    </div>
                    <div class="col-md-12 px-1">
                        <label>掲載ページ</label>
                        <div class="input-group">
                            <form-input-number
                                v-model="catalog_page.chapter"
                                min="0"
                                :disabled="!$store.getters['auth/canEditCatalog']()"
                            ></form-input-number>
                            <span class="input-group-text">章</span>
                            <form-input-number
                                v-model="catalog_page.section"
                                min="0"
                                :disabled="!$store.getters['auth/canEditCatalog']()"
                            ></form-input-number>
                            <span class="input-group-text">節</span>
                            <form-input-number
                                v-model="catalog_page.sequence"
                                min="0"
                                :disabled="!$store.getters['auth/canEditCatalog']()"
                            ></form-input-number>
                            <span class="input-group-text">項</span>
                            <form-input
                                v-model="catalog_page.page_number"
                                :disabled="!$store.getters['auth/canEditCatalog']()"
                            ></form-input>
                            <span class="input-group-text">ページ</span>
                        </div>
                    </div>
                    <div class="col-md-4 px-1">
                        <label>カタログURL</label>
                        <form-input
                            v-model="catalog_page.catalog_url"
                            maxlength="191"
                            :disabled="!$store.getters['auth/canEditCatalog']()"
                        ></form-input>
                    </div>
                    <div class="form-group col-md-2">
                        <button-exec
                            text=""
                            icon="bi-trash"
                            @click="removeCatalogPage(index)"
                            :disabled="!$store.getters['auth/canEditCatalog']()"
                        ></button-exec>
                    </div>
                </div>
            </template>
            <div class="mb-3">
                <button-exec
                    text="掲載ページを追加"
                    icon="bi-plus"
                    color="btn-outline-primary"
                    @click="addCatalogPage()"
                    :disabled="!$store.getters['auth/canEditCatalog']()"
                ></button-exec>
            </div>
            <div class="row align-items-end mb-3">
                <div class="form-group col-md-18">
                    <div class="input-group">
                        <span class="input-group-text">合成 (親)</span>
                        <form-input
                            v-model="merged_product_group_code"
                            placeholder="製品グループコード"
                            maxlength="191"
                            :disabled="!$store.getters['auth/canEditCatalog']()"
                            @change="setMergedProductGroup()"
                        ></form-input>
                        <span class="input-group-text form-control">{{ merged_product_group_name }}</span>
                    </div>
                </div>
            </div>
            <h4 class="mt-4">カタログ図</h4>
            <div class="mb-3">
                <form-upload-multiple
                    v-model="product_group_revision.catalog_drawings"
                    :upload_dir="UploadService.PRODUCT_GROUP_CATALOG"
                    :is_editable="$store.getters['auth/canEditCatalog']()"
                ></form-upload-multiple>
            </div>
            <h4 class="mt-4">規格図</h4>
            <div class="mb-3">
                <form-upload-multiple
                    v-model="product_group_revision.standard_drawings"
                    :upload_dir="UploadService.PRODUCT_GROUP_STANDARD"
                    :is_editable="$store.getters['auth/canEditStandard']()"
                ></form-upload-multiple>
            </div>
            <h4 class="mt-4">チラシ</h4>
            <div class="mb-3">
                <form-upload-multiple
                    v-model="product_group_revision.flyers"
                    :upload_dir="UploadService.PRODUCT_GROUP_FLYER"
                    :is_editable="$store.getters['auth/canEditCatalog']()"
                ></form-upload-multiple>
            </div>
            <h4 class="mt-4">動画</h4>
            <template v-for="movie, index in product_group_revision.movies" :key="movie">
                <div class="row align-items-end mb-3">
                    <div class="col-md-6 pe-1">
                        <label>動画名</label>
                        <form-input
                            v-model="movie.movie_name"
                            maxlength="191"
                            required="required"
                            :disabled="!$store.getters['auth/canEditCatalog']()"
                        ></form-input>
                    </div>
                    <div class="col-md-16 px-1">
                        <label>動画URL</label>
                        <form-input
                            v-model="movie.movie_url"
                            maxlength="191"
                            required="required"
                            :disabled="!$store.getters['auth/canEditCatalog']()"
                        ></form-input>
                    </div>
                    <div class="col-md-2">
                        <button-exec
                            text=""
                            icon="bi-trash"
                            @click="removeMovie(index)"
                            :disabled="!$store.getters['auth/canEditCatalog']()"
                        ></button-exec>
                    </div>
                </div>
            </template>
            <div class="mb-3">
                <button-exec
                    text="動画を追加"
                    icon="bi-plus"
                    color="btn-outline-primary"
                    @click="addMovie"
                    :disabled="!$store.getters['auth/canEditCatalog']()"
                ></button-exec>
            </div>
        </section>

        <section class="section">
            <h3 class="section-title">サブグループ情報</h3>
            <div class="row">
                <ul class="col nav nav-tabs mb-3">
                    <template
                        v-for="subgroup, index in product_group_revision.subgroups"
                        :key="subgroup"
                    >
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                style="cursor: pointer;"
                                :class="{'active': index === editing_subgroup_index}"
                                aria-current="page"
                                @click="setEditSubgroup(index)"
                            >{{ subgroup.product_subgroup_name }}</a>
                        </li>
                    </template>
                </ul>
                <div class="col-12 col-md-12 col-lg-8 d-flex justify-content-end">
                    <div v-if="product_group_revision.subgroups.length > 1">
                        <button-exec
                            color="btn-outline-primary"
                            text=""
                            :disabled="!$store.getters['auth/canEditBasic']()"
                            @click="openSortSubgroupModal();"
                        >
                            <i class="bi bi-arrow-down-up"></i> 並び替え (<i class="bi bi-trash"></i>)
                        </button-exec>
                    </div>
                    <div class="ms-2">
                        <button-exec
                            color="btn-outline-primary"
                            text=""
                            :disabled="!$store.getters['auth/canEditBasic']()"
                            @click="addSubgroup()"
                        >
                            <i class="bi bi-plus"></i> サブグループ追加
                        </button-exec>
                    </div>

                </div>
            </div>

            <div v-if="product_group_revision.subgroups.length <= 1" class="d-flex">
                <alert-no-record
                    text="下記項目（製作図・規格）以外の編集は、サブグループが2つ以上の場合に有効です"
                    class="small"
                    icon="bi-info-circle-fill"
                    icon_class="text-success"
                ></alert-no-record>
            </div>
            <template v-else>
                <div class="row align-items-end mb-3">
                    <div class="form-group col-md-6">
                        <label>サブグループ名</label>
                        <form-input
                            v-model="editing_subgroup.product_subgroup_name"
                            maxlength="191"
                            required="required"
                            :disabled="!$store.getters['auth/canEditBasic']()"
                        ></form-input>
                    </div>
                </div>

                <h4 class="mt-4">画像</h4>
                <div class="row mb-3">
                    <form-upload-multiple
                        v-model="editing_subgroup.images"
                        :upload_dir="UploadService.SUBGROUP_IMAGE"
                        :is_editable="$store.getters['auth/canEditCatalog']()"
                    ></form-upload-multiple>
                </div>

                <h4 class="mt-4">カタログ</h4>
                <div class="row align-items-end mb-3">
                    <div class="form-group col-md-6">
                        <form-checkbox
                            v-model="editing_subgroup.is_on_catalog"
                            option_label="カタログに掲載"
                            class="form-control"
                            :disabled="!$store.getters['auth/canEditCatalog']()"
                        ></form-checkbox>
                    </div>
                    <div class="form-group col-md-6">
                        <form-checkbox
                            v-model="editing_subgroup.is_price_on_catalog"
                            option_label="カタログに金額掲載"
                            class="form-control"
                            :disabled="!$store.getters['auth/canEditCatalog']()"
                        ></form-checkbox>
                    </div>
                </div>
                <template v-for="catalog_page, index in editing_subgroup.catalog_pages" :key="catalog_page">
                    <div class="row align-items-end mb-3">
                        <div class="col-md-6 pe-1">
                            <label>カタログ</label>
                            <form-select
                                v-model="catalog_page.catalog.catalog_id"
                                :options="select_catalogs"
                                empty_option="-- 選択 --"
                                required="required"
                                :disabled="!$store.getters['auth/canEditCatalog']()"
                            ></form-select>
                        </div>
                        <div class="col-md-12 px-1">
                            <label>掲載ページ</label>
                            <div class="input-group">
                                <form-input-number
                                    v-model="catalog_page.chapter"
                                    min="0"
                                    :disabled="!$store.getters['auth/canEditCatalog']()"
                                ></form-input-number>
                                <span class="input-group-text">章</span>
                                <form-input-number
                                    v-model="catalog_page.section"
                                    min="0"
                                    :disabled="!$store.getters['auth/canEditCatalog']()"
                                ></form-input-number>
                                <span class="input-group-text">節</span>
                                <form-input-number
                                    v-model="catalog_page.sequence"
                                    min="0"
                                    :disabled="!$store.getters['auth/canEditCatalog']()"
                                ></form-input-number>
                                <span class="input-group-text">項</span>
                                <form-input
                                    v-model="catalog_page.page_number"
                                    :disabled="!$store.getters['auth/canEditCatalog']()"
                                ></form-input>
                                <span class="input-group-text">ページ</span>
                            </div>
                        </div>
                        <div class="col-md-4 px-1">
                            <label>カタログURL</label>
                            <form-input
                                v-model="catalog_page.catalog_url"
                                maxlength="191"
                                :disabled="!$store.getters['auth/canEditCatalog']()"
                            ></form-input>
                        </div>
                        <div class="col-md-2">
                            <button-exec
                                text=""
                                icon="bi-trash"
                                @click="removeSubgroupCatalogPage(index)"
                                :disabled="!$store.getters['auth/canEditCatalog']()"
                            ></button-exec>
                        </div>
                    </div>
                </template>
                <div class="mb-3">
                    <button-exec
                        text="掲載ページを追加"
                        icon="bi-plus"
                        @click="addSubgroupCatalogPage()"
                        :disabled="!$store.getters['auth/canEditCatalog']()"
                    ></button-exec>
                </div>

                <h4 class="mt-4">カタログ図</h4>
                <div class="mb-3">
                    <form-upload-multiple
                        v-model="editing_subgroup.catalog_drawings"
                        :upload_dir="UploadService.SUBGROUP_CATALOG"
                        :is_editable="$store.getters['auth/canEditCatalog']()"
                    ></form-upload-multiple>
                </div>

                <h4 class="mt-4">規格図</h4>
                <div class="row mb-3">
                    <form-upload-multiple
                        v-model="editing_subgroup.standard_drawings"
                        :upload_dir="UploadService.SUBGROUP_STANDARD"
                        :is_editable="$store.getters['auth/canEditStandard']()"
                    ></form-upload-multiple>
                </div>
            </template>

            <!-- 製作図はサブグループ1つでも登録可能に -->
            <h4 class="mt-4">製作図</h4>
            <div class="row mb-3">
                <form-upload-multiple
                    v-model="editing_subgroup.production_drawings"
                    :upload_dir="UploadService.SUBGROUP_PRODUCTION"
                    :is_editable="$store.getters['auth/canEditProduction']()"
                ></form-upload-multiple>
            </div>

            <h4 class="mt-4">規格</h4>
            <div class="mb-3">
                <button-exec
                    text="カタログ用 規格項目設定"
                    icon="bi-gear"
                    @click="openCatalogSpecModal"
                ></button-exec>
            </div>
            <div class="mb-3">
                <button-exec
                    text="規格図用 規格項目設定"
                    icon="bi-gear"
                    @click="openStandardSpecModal"
                ></button-exec>
            </div>
            <div class="mb-3">
                <button-exec
                    text="製作図用 規格項目設定"
                    icon="bi-gear"
                    @click="openProductionSpecModal"
                    :disabled="!$store.getters['auth/canEditProduction']()"
                ></button-exec>
            </div>
        </section>
        <section class="section" v-if="product_group_revision.subgroups.length">
            <h3 class="section-title">製品情報 (<span class="text-success">{{ editing_subgroup.product_subgroup_name }}</span>)</h3>
            <table class="table product-table" v-if="editing_subgroup.products.length">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center align-middle col-1"></th>
                        <th class="text-center align-middle col-3">コード</th>
                        <th class="text-center align-middle col-3">定価</th>
                        <th class="text-center align-middle"></th>
                        <th class="text-center align-middle"></th>
                        <th class="text-center align-middle"></th>
                        <th class="text-center align-middle"></th>
                        <th class="text-center align-middle"></th>
                        <th class="text-center align-middle"></th>
                        <th class="text-center align-middle"></th>
                    </tr>
                </thead>
                <draggable
                    v-model="editing_subgroup.products"
                    item-key="product"
                    handle=".handle"
                    tag="tbody"
                >
                    <template #item="{element, index}">
                        <tr>
                        <td class="align-middle text-center" :class="{'handle': $store.getters['auth/canEditBasic']()}">
                            <i class="bi bi-chevron-bar-expand"></i>
                        </td>
                        <td class="align-middle">
                            <div class="product-code-id">
                                {{ element.product_code }} <div v-if="element.product_id" class="sid-small"><small>SID: </small>{{ element.product_id }}</div>
                            </div>
                        </td>
                        <td class="align-middle text-end">
                            {{ element.regular_price_display }}
                        </td>
                        <td class="text-center">
                            <button-exec
                                text="基本情報"
                                icon=""
                                @click="setEditProduct(element, index); openProductBasicModal();"
                            ></button-exec>
                        </td>
                        <td class="text-center">
                            <button-exec
                                text="カタログ掲載情報"
                                icon=""
                                @click="setEditProduct(element, index); openProductCatalogPageModal();"
                            ></button-exec>
                        </td>
                        <td class="text-center">
                            <button-exec
                                text="カタログ図情報"
                                icon=""
                                @click="setEditProduct(element, index); setModalProductCatalogDrawing(); syncCatalogSpecLabel(); openProductCatalogDrawingModal();"
                            ></button-exec>
                        </td>
                        <td class="text-center">
                            <button-exec
                                text="規格図情報"
                                icon=""
                                @click="setEditProduct(element, index); setModalProductStandardDrawing(); syncStandardSpecLabel(); openProductStandardDrawingModal();"
                            ></button-exec>
                        </td>
                        <td class="text-center">
                            <button-exec
                                text="製作情報"
                                icon=""
                                :disabled="!$store.getters['auth/canEditProduction']()"
                                @click="setEditProduct(element, index); syncProductionSpecLabel(); openProductProductionModal();"
                            ></button-exec>
                        </td>
                        <td class="text-center">
                            <button-exec
                                text="価格・掛率情報"
                                icon=""
                                @click="setEditProduct(element, index); openProductPriceRateModal();"
                            ></button-exec>
                        </td>
                        <td class="text-center">
                            <div class="dropdown">
                                <a class="btn btn-secondary" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-three-dots"></i>
                                </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <button-exec
                                            class="dropdown-item"
                                            text="構成品情報"
                                            icon="bi-pencil"
                                            color=""
                                            @click="setEditProduct(element, index); openProductComponentModal();"
                                        ></button-exec>
                                    </li>
                                    <li>
                                        <button-exec
                                            class="dropdown-item text-danger"
                                            text="製品を削除"
                                            color=""
                                            icon="bi-trash"
                                            :disabled="!$store.getters['auth/canEditBasic']()"
                                            @click="$refs.confirm_remove_product.show(); setTargetIndex(index);"
                                        ></button-exec>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    </template>
                </draggable>
            </table>
            <alert-no-Record v-else class="mb-3"></alert-no-Record>
            <div class="text-end">
                <button-exec
                    color="btn-outline-primary"
                    text="製品追加"
                    icon="bi-plus"
                    :disabled="!$store.getters['auth/canEditBasic']()"
                    @click="openAddProductModal()"
                ></button-exec>
            </div>
        </section>

        <section class="section">
            <div class="mb-3 input-group">
                <span class="input-group-text">リビジョン{{ product_group_revision.revision }}<br>変更理由</span>
                <form-textarea
                    v-model="product_group_revision.revision_comment"
                    row="'5'"
                ></form-textarea>
            </div>
        </section>

        <section class="section">
            <div class="d-flex justify-content-between">
                <button-exec-create
                    text="保存して公開"
                    icon=""
                    color="btn-success"
                    size="btn-lg"
                    :disabled="!$store.getters['auth/canEditAny']()"
                    @click="saveAndPublish()"
                ></button-exec-create>
                <button-exec-create
                    text="下書きとして保存"
                    icon=""
                    color="btn-danger"
                    size="btn-lg"
                    :disabled="!$store.getters['auth/canEditAny']()"
                    @click="saveAsDraft()"
                ></button-exec-create>
            </div>
            <div class="accordion mt-4" id="accordionExample">
                <div class="accordion-item border-0">
                    <h2 class="accordion-header border-0" id="headingTwo">
                        <button
                            type="button"
                            class="accordion-button collapsed text-end ps-0"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                        >
                            この製品グループを削除
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body text-center">
                        <!-- 編集権限を持つ かつ 公開区分が無効の場合 のみ操作可能 -->
                        <alert-no-record
                            v-if="!$store.getters['auth/canEditBasic']() || product_group_revision.public_type !== PublicType.INVALID"
                            text=""
                            class="mb-2"
                        >
                            削除権限（製品基本情報編集権限）があり、かつ製品グループの公開区分が「無効」である場合に、削除が可能です。
                        </alert-no-record>
                        <button-exec-delete
                            text="製品グループを削除"
                            color="btn-outline-danger"
                            size="btn-lg"
                            :disabled="!$store.getters['auth/canEditBasic']() || product_group_revision.public_type !== PublicType.INVALID"
                            @click="$refs.confirm_remove_all.show();"
                        ></button-exec-delete>
                    </div>
                    </div>
                </div>
            </div>
        </section>

    </template>
    <!-- 表示ここまで -->

    <!-- モーダル系 -->
    <!-- 規格変更履歴用モーダル -->
    <div v-if="workflowModal">
        <transition name="fade">
            <div v-if="workflowModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    規格変更履歴
                                </h4>
                            </div>
                            <div class="modal-body">
                                <template v-for="(workflow, index) in product_group_revision.workflows" :key="index">
                                    <div class="row align-items-end mb-3">
                                        <div class="col-md-7">
                                            <label>申請日付</label>
                                            <form-input-date
                                                v-model="workflow.workflow_date"
                                                required="required"
                                                :disabled="!$store.getters['auth/canEditBasic']()"
                                            ></form-input-date>
                                        </div>
                                        <div class="col-md-7">
                                            <label>規格変更管理番号</label>
                                            <form-input
                                                v-model="workflow.workflow_number"
                                                maxlength="191"
                                                required="required"
                                                :disabled="!$store.getters['auth/canEditBasic']()"
                                            ></form-input>
                                        </div>
                                        <div class="col-md-7">
                                            <label>完了状況</label>
                                            <form-select
                                                v-model="workflow.approved_status"
                                                :options="select_workflow_approved_status"
                                                empty_option="-- 選択 --"
                                                required="required"
                                                :disabled="!$store.getters['auth/canEditBasic']()"
                                            ></form-select>
                                        </div>
                                        <div class="col-md-2">
                                            <button-exec
                                                text=""
                                                icon="bi-trash"
                                                @click="removeWorkflow(index)"
                                                :disabled="!$store.getters['auth/canEditBasic']()"
                                            ></button-exec>
                                        </div>
                                    </div>
                                </template>
                                <button-exec
                                    text="規格変更履歴を追加"
                                    icon="bi-plus"
                                    color="btn-outline-primary"
                                    @click="addWorkflow"
                                    :disabled="!$store.getters['auth/canEditBasic']()"
                                ></button-exec>
                            </div>
                            <div class="modal-footer">
                                <button-exec
                                    text="完了"
                                    icon=""
                                    color="btn-secondary"
                                    @click="closeWorkflowModal"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- サブグループ並び替えモーダル -->
    <div v-if="sortSubgroupModal">
        <transition name="fade">
            <div v-if="sortSubgroupModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    サブグループ並び替え
                                </h4>
                            </div>
                            <div class="modal-body">
                                <table class="table border-top">
                                    <draggable
                                        v-model="product_group_revision.subgroups"
                                        item-key="subgroup"
                                        handle=".handle"
                                        tag="tbody"
                                    >
                                        <template #item="{element, index}">
                                            <tr>
                                                <td class="align-middle handle col-2 text-center">
                                                    <i class="bi bi-chevron-bar-expand"></i>
                                                </td>
                                                <td class="align-middle">
                                                    {{ element.product_subgroup_name }}
                                                </td>
                                                <td class="align-middle text-end">
                                                    <button-exec
                                                        v-if="product_group_revision.subgroups.length > 1"
                                                        text=""
                                                        icon="bi-trash"
                                                        @click="$refs.confirm_remove_subgroup.show(); setTargetIndex(index);"
                                                    ></button-exec>
                                                </td>
                                            </tr>
                                        </template>
                                    </draggable>
                                </table>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    @click="setSubgroupPriority(); closeSortSubgroupModal();"
                                >完了</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- カタログ用規格項目設定モーダル -->
    <div v-if="catalogSpecModal">
        <transition name="fade">
            <div v-if="catalogSpecModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    カタログ用 規格項目設定 (<span class="text-success" v-if="product_group_revision.subgroups.length > 0">{{ editing_subgroup.product_subgroup_name }}</span>)
                                </h4>
                            </div>
                            <div class="modal-body">
                                <table class="table" v-if="product_group_revision.subgroups.length > 0">
                                    <thead>
                                        <tr class="table-dark">
                                            <th class="text-center align-middle"></th>
                                            <th class="text-center align-middle">規格項目</th>
                                            <th class="text-center align-middle">前単位</th>
                                            <th class="text-center align-middle">後単位</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <draggable
                                        v-model="editing_subgroup.catalog_spec_labels"
                                        item-key="subgroup"
                                        handle=".handle"
                                        tag="tbody"
                                    >
                                        <template #item="{element, index}">
                                            <tr>
                                                <td class="align-middle col-2 text-center" :class="{'handle': $store.getters['auth/canEditCatalog']()}">
                                                    <i class="bi bi-chevron-bar-expand"></i>
                                                </td>
                                                <td class="align-middle">
                                                    {{ specLabelDisplay(element.spec_label.spec_label_id) }} <span class="small text-secondary ms-1">※ {{ specLabelInputTypeDisplay(element.spec_label.spec_label_id) }}</span>
                                                </td>
                                                <td class="align-middle">
                                                    <form-select
                                                        v-model="element.spec_prefix.spec_prefix_id"
                                                        :options="select_spec_prefixes"
                                                        empty_option="-- なし --"
                                                        :disabled="!$store.getters['auth/canEditCatalog']()"
                                                    ></form-select>
                                                </td>
                                                <td class="align-middle">
                                                    <form-select
                                                        v-model="element.spec_suffix.spec_suffix_id"
                                                        :options="select_spec_suffixes"
                                                        empty_option="-- なし --"
                                                        :disabled="!$store.getters['auth/canEditCatalog']()"
                                                    ></form-select>
                                                </td>
                                                <td class="align-middle text-end">
                                                    <button-exec
                                                        text=""
                                                        icon="bi-trash"
                                                        @click="$refs.confirm_remove_catalog_spec_label.show(); setTargetIndex(index);"
                                                        :disabled="!$store.getters['auth/canEditCatalog']()"
                                                    ></button-exec>
                                                </td>
                                            </tr>
                                        </template>
                                    </draggable>
                                </table>
                                <div class="row align-items-end">
                                    <div class="form-group col-md-8">
                                        <form-select
                                            v-model="add_spec_label_id"
                                            :options="select_spec_labels"
                                            empty_option="-- 選択 --"
                                            :disabled="!$store.getters['auth/canEditCatalog']()"
                                        ></form-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <button-exec
                                            text="項目追加"
                                            icon="bi-plus"
                                            color="btn-outline-primary"
                                            @click="addCatalogSpecLabel()"
                                            :disabled="!$store.getters['auth/canEditCatalog']()"
                                        ></button-exec>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button-exec
                                    text="完了"
                                    icon=""
                                    color="btn-secondary"
                                    @click="closeCatalogSpecModal"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- 規格図用規格項目設定モーダル -->
    <div v-if="standardSpecModal">
        <transition name="fade">
            <div v-if="standardSpecModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    規格図用 規格項目設定 (<span class="text-success" v-if="product_group_revision.subgroups.length > 0">{{ editing_subgroup.product_subgroup_name }}</span>)
                                </h4>
                            </div>
                            <div class="modal-body">
                                <table class="table" v-if="product_group_revision.subgroups.length > 0">
                                    <thead>
                                        <tr class="table-dark">
                                            <th class="text-center align-middle"></th>
                                            <th class="text-center align-middle">規格項目</th>
                                            <th class="text-center align-middle">前単位</th>
                                            <th class="text-center align-middle">後単位</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <draggable
                                        v-model="editing_subgroup.standard_spec_labels"
                                        item-key="subgroup"
                                        handle=".handle"
                                        tag="tbody"
                                    >
                                        <template #item="{element, index}">
                                            <tr>
                                                <td class="align-middle col-2 text-center" :class="{'handle': $store.getters['auth/canEditStandard']()}">
                                                    <i class="bi bi-chevron-bar-expand"></i>
                                                </td>
                                                <td class="align-middle">
                                                    {{ specLabelDisplay(element.spec_label.spec_label_id) }} <span class="small text-secondary ms-1">※ {{ specLabelInputTypeDisplay(element.spec_label.spec_label_id) }}</span>
                                                </td>
                                                <td class="align-middle">
                                                    <form-select
                                                        v-model="element.spec_prefix.spec_prefix_id"
                                                        :options="select_spec_prefixes"
                                                        empty_option="-- なし --"
                                                        :disabled="!$store.getters['auth/canEditStandard']()"
                                                    ></form-select>
                                                </td>
                                                <td class="align-middle">
                                                    <form-select
                                                        v-model="element.spec_suffix.spec_suffix_id"
                                                        :options="select_spec_suffixes"
                                                        empty_option="-- なし --"
                                                        :disabled="!$store.getters['auth/canEditStandard']()"
                                                    ></form-select>
                                                </td>
                                                <td class="align-middle text-end">
                                                    <button-exec
                                                        text=""
                                                        icon="bi-trash"
                                                        @click="$refs.confirm_remove_standard_spec_label.show(); setTargetIndex(index);"
                                                        :disabled="!$store.getters['auth/canEditStandard']()"
                                                    ></button-exec>
                                                </td>
                                            </tr>
                                        </template>
                                    </draggable>
                                </table>
                                <div class="row align-items-end">
                                    <div class="form-group col-md-8">
                                        <form-select
                                            v-model="add_spec_label_id"
                                            :options="select_spec_labels"
                                            empty_option="-- 選択 --"
                                            :disabled="!$store.getters['auth/canEditStandard']()"
                                        ></form-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <button-exec
                                            text="項目追加"
                                            icon="bi-plus"
                                            color="btn-outline-primary"
                                            @click="addStandardSpecLabel()"
                                            :disabled="!$store.getters['auth/canEditStandard']()"
                                        ></button-exec>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button-exec
                                    text="完了"
                                    icon=""
                                    color="btn-secondary"
                                    @click="closeStandardSpecModal"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- 製作図用規格項目設定モーダル -->
    <div v-if="productionSpecModal">
        <transition name="fade">
            <div v-if="productionSpecModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    製作図用 規格項目設定 (<span class="text-success" v-if="product_group_revision.subgroups.length > 0">{{ editing_subgroup.product_subgroup_name }}</span>)
                                </h4>
                            </div>
                            <div class="modal-body">
                                <table class="table" v-if="product_group_revision.subgroups.length > 0">
                                    <thead>
                                        <tr class="table-dark">
                                            <th class="text-center align-middle"></th>
                                            <th class="text-center align-middle">規格項目</th>
                                            <th class="text-center align-middle">前単位</th>
                                            <th class="text-center align-middle">後単位</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <draggable
                                        v-model="editing_subgroup.production_spec_labels"
                                        item-key="subgroup"
                                        handle=".handle"
                                        tag="tbody"
                                    >
                                        <template #item="{element, index}">
                                            <tr>
                                                <td class="align-middle col-2 text-center" :class="{'handle': $store.getters['auth/canEditProduction']()}">
                                                    <i class="bi bi-chevron-bar-expand"></i>
                                                </td>
                                                <td class="align-middle">
                                                    {{ specLabelDisplay(element.spec_label.spec_label_id) }} <span class="small text-secondary ms-1">※ {{ specLabelInputTypeDisplay(element.spec_label.spec_label_id) }}</span>
                                                </td>
                                                <td class="align-middle">
                                                    <form-select
                                                        v-model="element.spec_prefix.spec_prefix_id"
                                                        :options="select_spec_prefixes"
                                                        empty_option="-- なし --"
                                                        :disabled="!$store.getters['auth/canEditProduction']()"
                                                    ></form-select>
                                                </td>
                                                <td class="align-middle">
                                                    <form-select
                                                        v-model="element.spec_suffix.spec_suffix_id"
                                                        :options="select_spec_suffixes"
                                                        empty_option="-- なし --"
                                                        :disabled="!$store.getters['auth/canEditProduction']()"
                                                    ></form-select>
                                                </td>
                                                <td class="align-middle text-end">
                                                    <button-exec
                                                        text=""
                                                        icon="bi-trash"
                                                        @click="$refs.confirm_remove_production_spec_label.show(); setTargetIndex(index);"
                                                        :disabled="!$store.getters['auth/canEditProduction']()"
                                                    ></button-exec>
                                                </td>
                                            </tr>
                                        </template>
                                    </draggable>
                                </table>
                                <div class="row align-items-end">
                                    <div class="form-group col-md-8">
                                        <form-select
                                            v-model="add_spec_label_id"
                                            :options="select_spec_labels"
                                            empty_option="-- 選択 --"
                                            :disabled="!$store.getters['auth/canEditProduction']()"
                                        ></form-select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <button-exec
                                            text="項目追加"
                                            icon="bi-plus"
                                            color="btn-outline-primary"
                                            @click="addProductionSpecLabel()"
                                            :disabled="!$store.getters['auth/canEditProduction']()"
                                        ></button-exec>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button-exec
                                    text="完了"
                                    icon=""
                                    color="btn-secondary"
                                    @click="closeProductionSpecModal"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- 基本情報モーダル -->
    <div v-if="productBasicModal">
        <transition name="fade">
            <div v-if="productBasicModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    基本情報 ({{ product_group_revision.product_group_code }}-{{ editing_product.product_code }})
                                </h4>
                                <button-cancel-close
                                    text=""
                                    @click="cancelProductBasicModal()"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="入力をキャンセル"
                                ></button-cancel-close>
                            </div>
                            <div class="modal-body">
                                <div class="row align-items-start mb-3">
                                    <div class="col-md-8">
                                        <label>公開区分</label>
                                        <form-select
                                            v-model="editing_product.public_type"
                                            :options="public_types"
                                            :required="true"
                                            empty_option="-- 選択 --"
                                            :disabled="!$store.getters['auth/canEditBasic']()"
                                        ></form-select>
                                    </div>
                                    <div class="col-md-8">
                                        <label>ステータス</label>
                                        <form-select
                                            v-model="editing_product.availability"
                                            :options="availabilities"
                                            :required="true"
                                            empty_option="-- 選択 --"
                                            :disabled="!$store.getters['auth/canEditBasic']()"
                                        ></form-select>
                                    </div>
                                    <div class="col-md-8">
                                        <label>取引停止日</label>
                                        <form-input-date
                                            v-model="editing_product.pending_date"
                                            :disabled="!$store.getters['auth/canEditBasic']()"
                                        ></form-input-date>
                                    </div>
                                    <div class="col-md-8">
                                    </div>
                                </div>
                                <div class="row align-items-start mb-3">
                                    <div class="form-group col-md-12">
                                        <label>製品コード</label>
                                        <div class="input-group">
                                            <span class="input-group-text">{{ product_group_revision.product_group_code }}-</span>
                                            <form-input
                                                v-model="editing_product.product_code"
                                                maxlength="191"
                                                required="required"
                                                :disabled="!$store.getters['auth/canEditBasic']()"
                                            ></form-input>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label>販売価格</label>
                                        <div class="input-group">
                                            <div class="input-group-text">
                                                <form-checkbox
                                                    v-model="editing_product.is_each_time"
                                                    option_label="都度見積"
                                                    :disabled="!$store.getters['auth/canEditBasic']()"
                                                ></form-checkbox>
                                            </div>
                                            <form-input-number
                                                v-model="editing_product.regular_price"
                                                min="0"
                                                :disabled="!$store.getters['auth/canEditBasic']()"
                                            ></form-input-number>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-start mb-3">
                                    <div class="form-group col-md-12">
                                        <label>Parts No.</label>
                                        <form-input
                                            v-model="editing_product.parts_number"
                                            maxlength="10"
                                            :disabled="!$store.getters['auth/canEditBasic']()"
                                        ></form-input>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label>製品型式</label>
                                        <form-input
                                            v-model="editing_product.model_number"
                                            maxlength="191"
                                            :disabled="!$store.getters['auth/canEditBasic']()"
                                        ></form-input>
                                    </div>
                                </div>
                                <div class="row align-items-start mb-3">
                                    <div class="form-group col-md-12">
                                        <label>生産区分</label>
                                        <form-select
                                            v-model="editing_product.production_type"
                                            :options="production_types"
                                            empty_option="-- 選択 --"
                                            :disabled="!$store.getters['auth/canEditBasic']()"
                                        ></form-select>
                                    </div>
                                </div>
                                <div class="row align-items-start mb-3">
                                    <div class="form-group col-md-12">
                                        <label>項目1</label>
                                        <form-input
                                            v-model="editing_product.field_1"
                                            maxlength="191"
                                            :disabled="!$store.getters['auth/canEditBasic']()"
                                        ></form-input>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label>項目2</label>
                                        <form-input
                                            v-model="editing_product.field_2"
                                            maxlength="191"
                                            :disabled="!$store.getters['auth/canEditBasic']()"
                                        ></form-input>
                                    </div>
                                </div>
                                <div class="row align-items-start mb-3">
                                    <div class="form-group col-md-12">
                                        <label>項目3</label>
                                        <form-input
                                            v-model="editing_product.field_3"
                                            maxlength="191"
                                            :disabled="!$store.getters['auth/canEditBasic']()"
                                        ></form-input>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button-exec
                                    text="完了"
                                    icon=""
                                    color="btn-secondary"
                                    @click="closeProductBasicModal(editing_product)"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- カタログ掲載情報モーダル -->
    <div v-if="productCatalogPageModal">
        <transition name="fade">
            <div v-if="productCatalogPageModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    カタログ掲載情報 ({{ product_group_revision.product_group_code }}-{{ editing_product.product_code }})
                                </h4>
                                <button-cancel-close
                                    text=""
                                    @click="cancelProductCatalogPageModal();"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="入力をキャンセル"
                                ></button-cancel-close>
                            </div>
                            <div class="modal-body">
                                <div class="row align-items-end mb-3">
                                    <div class="form-group col-md-12">
                                        <label>カタログ掲載フラグ</label>
                                        <form-checkbox
                                            v-model="editing_product.is_on_catalog"
                                            option_label="カタログに掲載"
                                            class="form-control"
                                            :disabled="!$store.getters['auth/canEditCatalog']()"
                                        ></form-checkbox>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label>カタログ金額掲載フラグ</label>
                                        <form-checkbox
                                            v-model="editing_product.is_price_on_catalog"
                                            option_label="カタログに金額掲載"
                                            class="form-control"
                                            :disabled="!$store.getters['auth/canEditCatalog']()"
                                        ></form-checkbox>
                                    </div>
                                </div>
                                <h4 class="mt-4">画像</h4>
                                <div class="row mb-3">
                                    <form-upload-multiple
                                        v-model="editing_product.images"
                                        :upload_dir="UploadService.PRODUCT_IMAGE"
                                        :is_editable="$store.getters['auth/canEditCatalog']()"
                                ></form-upload-multiple>
                                </div>
                                <div class="mb-3">
                                    <div class="form-group">
                                        <label>説明</label>
                                        <form-textarea
                                            v-model="editing_product.description"
                                            row="'2'"
                                            :disabled="!$store.getters['auth/canEditCatalog']()"
                                        ></form-textarea>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="form-group">
                                        <label>説明 (英語)</label>
                                        <form-textarea
                                            v-model="editing_product.description_en"
                                            row="'2'"
                                            :disabled="!$store.getters['auth/canEditCatalog']()"
                                        ></form-textarea>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="form-group">
                                        <label>注釈</label>
                                        <form-textarea
                                            v-model="editing_product.note"
                                            row="'2'"
                                            :disabled="!$store.getters['auth/canEditCatalog']()"
                                        ></form-textarea>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="form-group">
                                        <label>注釈（英語）</label>
                                        <form-textarea
                                            v-model="editing_product.note_en"
                                            row="'2'"
                                            :disabled="!$store.getters['auth/canEditCatalog']()"
                                        ></form-textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button-exec
                                    text="完了"
                                    icon=""
                                    color="btn-secondary"
                                    @click="closeProductCatalogPageModal(editing_product)"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- カタログ図情報モーダル -->
    <div v-if="productCatalogDrawingModal">
        <transition name="fade">
            <div v-if="productCatalogDrawingModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    カタログ図情報 ({{ product_group_revision.product_group_code }}-{{ editing_product.product_code }})
                                </h4>
                                <button-cancel-close
                                    text=""
                                    @click="cancelProductCatalogDrawingModal();"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="入力をキャンセル"
                                ></button-cancel-close>
                            </div>
                            <div class="modal-body row">
                                <div class="col-12">
                                    <figure>
                                        <img :src="modal_catalog_drawing" alt="" style="width: 100%;">
                                    </figure>
                                </div>
                                <div class="col-12">
                                    <table class="table">
                                        <tbody>
                                            <template v-for="catalog_spec_value in editing_product.catalog_spec_values" :key="catalog_spec_value">
                                                <tr>
                                                    <td class="align-middle nowrap">
                                                        {{ specLabelDisplay(catalog_spec_value.catalog_spec_label.spec_label.spec_label_id) }} <span class="small text-secondary ms-1">※ {{ specLabelInputTypeDisplay(catalog_spec_value.catalog_spec_label.spec_label.spec_label_id) }}</span>
                                                    </td>
                                                    <td class="align-middle">
                                                        <div class="input-group">
                                                            <span
                                                                v-if="catalog_spec_value.catalog_spec_label.spec_prefix.spec_prefix_id"
                                                                class="input-group-text"
                                                            >{{ specPrefixDisplay(catalog_spec_value.catalog_spec_label.spec_prefix.spec_prefix_id) }}</span>
                                                            <!-- 規格値 -->
                                                            <!-- 文字列 -->
                                                            <form-input
                                                                v-if="Number(catalog_spec_value.catalog_spec_label.spec_label.input_type) === InputType.STRING"
                                                                v-model="catalog_spec_value.spec_value"
                                                                maxlength="191"
                                                                :disabled="!$store.getters['auth/canEditCatalog']()"
                                                            ></form-input>
                                                            <!-- 整数 -->
                                                            <form-input-number
                                                                v-if="Number(catalog_spec_value.catalog_spec_label.spec_label.input_type) === InputType.INTEGER"
                                                                v-model="catalog_spec_value.spec_value"
                                                                min="0"
                                                                :disabled="!$store.getters['auth/canEditCatalog']()"
                                                            ></form-input-number>
                                                            <!-- 数値(少数1位) -->
                                                            <form-input-fraction
                                                                v-if="Number(catalog_spec_value.catalog_spec_label.spec_label.input_type) === InputType.DECIMAL_1"
                                                                v-model="catalog_spec_value.spec_value"
                                                                :disabled="!$store.getters['auth/canEditCatalog']()"
                                                            ></form-input-fraction>
                                                            <!-- 数値(少数2位) -->
                                                            <form-input-fraction
                                                                v-if="Number(catalog_spec_value.catalog_spec_label.spec_label.input_type) === InputType.DECIMAL_2"
                                                                v-model="catalog_spec_value.spec_value"
                                                                :step=".01"
                                                                :disabled="!$store.getters['auth/canEditCatalog']()"
                                                            ></form-input-fraction>
                                                            <span
                                                                v-if="catalog_spec_value.catalog_spec_label.spec_suffix.spec_suffix_id"
                                                                class="input-group-text"
                                                            >{{ specSuffixDisplay(catalog_spec_value.catalog_spec_label.spec_suffix.spec_suffix_id) }}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button-exec
                                    text="規格図情報と並べて編集"
                                    icon=""
                                    @click="openProductCatalogStandardModal()"
                                ></button-exec>
                                <button-exec
                                    text="完了"
                                    icon=""
                                    color="btn-secondary"
                                    @click="closeProductCatalogDrawingModal()"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- 規格図情報モーダル -->
    <div v-if="productStandardDrawingModal">
        <transition name="fade">
            <div v-if="productStandardDrawingModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    規格図情報 ({{ product_group_revision.product_group_code }}-{{ editing_product.product_code }})
                                </h4>
                                <button-cancel-close
                                    text=""
                                    @click="cancelProductStandardDrawingModal();"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="入力をキャンセル"
                                ></button-cancel-close>
                            </div>
                            <div class="modal-body row">
                                <div class="col-12">
                                    <figure>
                                        <img :src="modal_standard_drawing" alt="" style="width: 100%;">
                                    </figure>
                                </div>
                                <div class="col-12">
                                    <table class="table">
                                        <tbody>
                                            <template v-for="standard_spec_value in editing_product.standard_spec_values" :key="standard_spec_value">
                                                <tr>
                                                    <td class="align-middle nowrap">
                                                        {{ specLabelDisplay(standard_spec_value.standard_spec_label.spec_label.spec_label_id) }} <span class="small text-secondary ms-1">※ {{ specLabelInputTypeDisplay(standard_spec_value.standard_spec_label.spec_label.spec_label_id) }}</span>
                                                    </td>
                                                    <td class="align-middle">
                                                        <div class="input-group">
                                                            <span
                                                                v-if="standard_spec_value.standard_spec_label.spec_prefix.spec_prefix_id"
                                                                class="input-group-text"
                                                            >{{ specPrefixDisplay(standard_spec_value.standard_spec_label.spec_prefix.spec_prefix_id) }}</span>
                                                            <!-- 規格値 -->
                                                            <!-- 文字列 -->
                                                            <form-input
                                                                v-if="Number(standard_spec_value.standard_spec_label.spec_label.input_type) === InputType.STRING"
                                                                v-model="standard_spec_value.spec_value"
                                                                maxlength="191"
                                                                :disabled="!$store.getters['auth/canEditStandard']()"
                                                            ></form-input>
                                                            <!-- 整数 -->
                                                            <form-input-number
                                                                v-if="Number(standard_spec_value.standard_spec_label.spec_label.input_type) === InputType.INTEGER"
                                                                v-model="standard_spec_value.spec_value"
                                                                min="0"
                                                                :disabled="!$store.getters['auth/canEditStandard']()"
                                                            ></form-input-number>
                                                            <!-- 数値(少数1位) -->
                                                            <form-input-fraction
                                                                v-if="Number(standard_spec_value.standard_spec_label.spec_label.input_type) === InputType.DECIMAL_1"
                                                                v-model="standard_spec_value.spec_value"
                                                                :disabled="!$store.getters['auth/canEditStandard']()"
                                                            ></form-input-fraction>
                                                            <!-- 数値(少数2位) -->
                                                            <form-input-fraction
                                                                v-if="Number(standard_spec_value.standard_spec_label.spec_label.input_type) === InputType.DECIMAL_2"
                                                                v-model="standard_spec_value.spec_value"
                                                                :step=".01"
                                                                :disabled="!$store.getters['auth/canEditStandard']()"
                                                            ></form-input-fraction>
                                                            <span
                                                                v-if="standard_spec_value.standard_spec_label.spec_suffix.spec_suffix_id"
                                                                class="input-group-text"
                                                            >{{ specSuffixDisplay(standard_spec_value.standard_spec_label.spec_suffix.spec_suffix_id) }}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button-exec
                                    text="カタログ図情報と並べて編集"
                                    icon=""
                                    @click="openProductCatalogStandardModal()"
                                ></button-exec>
                                <button-exec
                                    text="完了"
                                    icon=""
                                    color="btn-secondary"
                                    @click="closeProductStandardDrawingModal()"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- カタログ図 / 規格図情報モーダル -->
    <div v-if="productCatalogStandardModal">
        <transition name="fade">
            <div v-if="productCatalogStandardModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    カタログ図 / 規格図情報 ({{ product_group_revision.product_group_code }}-{{ editing_product.product_code }})
                                </h4>
                                <button-cancel-close
                                    text=""
                                    @click="cancelProductCatalogStandardModal();"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="入力をキャンセル"
                                ></button-cancel-close>
                            </div>
                            <div class="modal-body row">
                                <div class="col-8">
                                    <ul class="nav nav-tabs mb-3">
                                        <li class="nav-item">
                                            <a
                                                class="nav-link"
                                                :class="{active:is_shown_catalog_drawing}"
                                                role="button"
                                                aria-current="page"
                                                @click="is_shown_catalog_drawing = true; is_shown_standard_drawing = false;"
                                            >カタログ図</a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class="nav-link"
                                                :class="{active:is_shown_standard_drawing}"
                                                role="button"
                                                aria-current="page"
                                                @click="is_shown_standard_drawing = true; is_shown_catalog_drawing = false;"
                                            >規格図</a>
                                        </li>
                                    </ul>
                                    <!-- <figure class="form-upload-image mb-1">
                                        <img
                                            :src="element.thumbnail_url + '?access_token=' + this.$store.state.auth.access_token"
                                            :alt="element.file_label"
                                        >
                                    </figure> -->
                                    <figure>
                                        <img :src="modal_catalog_drawing" alt="" style="width: 100%;" v-if="is_shown_catalog_drawing">
                                        <img :src="modal_standard_drawing" alt="" style="width: 100%;" v-if="is_shown_standard_drawing">
                                    </figure>
                                </div>
                                <div class="col-8">
                                    <h5>カタログ図規格</h5>
                                    <table class="table">
                                        <tbody>
                                            <template v-for="catalog_spec_value in editing_product.catalog_spec_values" :key="catalog_spec_value">
                                                <tr>
                                                    <td class="align-middle nowrap">
                                                        {{ specLabelDisplay(catalog_spec_value.catalog_spec_label.spec_label.spec_label_id) }}
                                                    </td>
                                                    <td class="align-middle">
                                                        <div class="input-group">
                                                            <span
                                                                v-if="catalog_spec_value.catalog_spec_label.spec_prefix.spec_prefix_id"
                                                                class="input-group-text"
                                                            >{{ specPrefixDisplay(catalog_spec_value.catalog_spec_label.spec_prefix.spec_prefix_id) }}</span>
                                                            <!-- 規格値 -->
                                                            <!-- 文字列 -->
                                                            <form-input
                                                                v-if="Number(catalog_spec_value.catalog_spec_label.spec_label.input_type) === InputType.STRING"
                                                                v-model="catalog_spec_value.spec_value"
                                                                maxlength="191"
                                                                :disabled="!$store.getters['auth/canEditCatalog']()"
                                                            ></form-input>
                                                            <!-- 整数 -->
                                                            <form-input-number
                                                                v-if="Number(catalog_spec_value.catalog_spec_label.spec_label.input_type) === InputType.INTEGER"
                                                                v-model="catalog_spec_value.spec_value"
                                                                min="0"
                                                                :disabled="!$store.getters['auth/canEditCatalog']()"
                                                            ></form-input-number>
                                                            <!-- 数値(少数1位) -->
                                                            <form-input-fraction
                                                                v-if="Number(catalog_spec_value.catalog_spec_label.spec_label.input_type) === InputType.DECIMAL_1"
                                                                v-model="catalog_spec_value.spec_value"
                                                                :disabled="!$store.getters['auth/canEditCatalog']()"
                                                            ></form-input-fraction>
                                                            <!-- 数値(少数2位) -->
                                                            <form-input-fraction
                                                                v-if="Number(catalog_spec_value.catalog_spec_label.spec_label.input_type) === InputType.DECIMAL_2"
                                                                v-model="catalog_spec_value.spec_value"
                                                                :step=".01"
                                                                :disabled="!$store.getters['auth/canEditCatalog']()"
                                                            ></form-input-fraction>
                                                            <span
                                                                v-if="catalog_spec_value.catalog_spec_label.spec_suffix.spec_suffix_id"
                                                                class="input-group-text"
                                                            >{{ specSuffixDisplay(catalog_spec_value.catalog_spec_label.spec_suffix.spec_suffix_id) }}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-8">
                                    <h5>規格図規格</h5>
                                    <table class="table">
                                        <tbody>
                                            <template v-for="standard_spec_value in editing_product.standard_spec_values" :key="standard_spec_value">
                                                <tr>
                                                    <td class="align-middle nowrap">
                                                        {{ specLabelDisplay(standard_spec_value.standard_spec_label.spec_label.spec_label_id) }}
                                                    </td>
                                                    <td class="align-middle">
                                                        <div class="input-group">
                                                            <span
                                                                v-if="standard_spec_value.standard_spec_label.spec_prefix.spec_prefix_id"
                                                                class="input-group-text"
                                                            >{{ specPrefixDisplay(standard_spec_value.standard_spec_label.spec_prefix.spec_prefix_id) }}</span>
                                                            <!-- 規格値 -->
                                                            <!-- 文字列 -->
                                                            <form-input
                                                                v-if="Number(standard_spec_value.standard_spec_label.spec_label.input_type) === InputType.STRING"
                                                                v-model="standard_spec_value.spec_value"
                                                                maxlength="191"
                                                                :disabled="!$store.getters['auth/canEditStandard']()"
                                                            ></form-input>
                                                            <!-- 整数 -->
                                                            <form-input-number
                                                                v-if="Number(standard_spec_value.standard_spec_label.spec_label.input_type) === InputType.INTEGER"
                                                                v-model="standard_spec_value.spec_value"
                                                                min="0"
                                                                :disabled="!$store.getters['auth/canEditStandard']()"
                                                            ></form-input-number>
                                                            <!-- 数値(少数1位) -->
                                                            <form-input-fraction
                                                                v-if="Number(standard_spec_value.standard_spec_label.spec_label.input_type) === InputType.DECIMAL_1"
                                                                v-model="standard_spec_value.spec_value"
                                                                :disabled="!$store.getters['auth/canEditStandard']()"
                                                            ></form-input-fraction>
                                                            <!-- 数値(少数2位) -->
                                                            <form-input-fraction
                                                                v-if="Number(standard_spec_value.standard_spec_label.spec_label.input_type) === InputType.DECIMAL_2"
                                                                v-model="standard_spec_value.spec_value"
                                                                :step=".01"
                                                                :disabled="!$store.getters['auth/canEditStandard']()"
                                                            ></form-input-fraction>
                                                            <span
                                                                v-if="standard_spec_value.standard_spec_label.spec_suffix.spec_suffix_id"
                                                                class="input-group-text"
                                                            >{{ specSuffixDisplay(standard_spec_value.standard_spec_label.spec_suffix.spec_suffix_id) }}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button-exec
                                    text="完了"
                                    icon=""
                                    color="btn-secondary"
                                    @click="closeProductCatalogStandardModal()"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- 製作情報モーダル -->
    <div v-if="productProductionModal">
        <transition name="fade">
            <div v-if="productProductionModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    製作情報 ({{ product_group_revision.product_group_code }}-{{ editing_product.product_code }})
                                </h4>
                                <button-cancel-close
                                    text=""
                                    @click="cancelProductProductionModal();"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="入力をキャンセル"
                                ></button-cancel-close>
                            </div>
                            <div class="modal-body">
                                <div class="row align-items-end mb-3">
                                    <div class="form-group col-md-8">
                                        <label>原価</label>
                                        <div class="input-group">
                                            <form-input-number
                                                v-model="editing_product.production_cost"
                                                min="0"
                                                :disabled="!$store.getters['auth/canEditProduction']()"
                                            ></form-input-number>
                                            <span class="input-group-text">円</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-8">
                                        <label>作業時間</label>
                                        <div class="input-group">
                                            <form-input-fraction
                                                v-model="editing_product.production_hours"
                                                :step="0.1"
                                                min="0.1"
                                                :disabled="!$store.getters['auth/canEditProduction']()"
                                            ></form-input-fraction>
                                            <span class="input-group-text">人日</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-end mb-3">
                                    <div class="form-group col-md-8">
                                        <label>PB</label>
                                        <div class="input-group">
                                            <form-input-fraction
                                                v-model="editing_product.product_buffer"
                                                :step="0.1"
                                                min="0.1"
                                                :disabled="!$store.getters['auth/canEditProduction']()"
                                            ></form-input-fraction>
                                            <span class="input-group-text">人日</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-8">
                                        <label>PB該当数量</label>
                                        <div class="input-group">
                                            <form-input-number
                                                v-model="editing_product.product_buffer_limit"
                                                min="0"
                                                :disabled="!$store.getters['auth/canEditProduction']()"
                                            ></form-input-number>
                                            <span class="input-group-text">個</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-end mb-3">
                                    <div class="form-group col-md-8">
                                        <label>PB (R)</label>
                                        <div class="input-group">
                                            <form-input-fraction
                                                v-model="editing_product.product_buffer_red"
                                                :step="0.1"
                                                min="0.1"
                                                :disabled="!$store.getters['auth/canEditProduction']()"
                                            ></form-input-fraction>
                                            <span class="input-group-text">人日</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-8">
                                        <label>PB (Y)</label>
                                        <div class="input-group">
                                            <form-input-fraction
                                                v-model="editing_product.product_buffer_yellow"
                                                :step="0.1"
                                                min="0.1"
                                                :disabled="!$store.getters['auth/canEditProduction']()"
                                            ></form-input-fraction>
                                            <span class="input-group-text">人日</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-8">
                                        <label>PB (G)</label>
                                        <div class="input-group">
                                            <form-input-fraction
                                                v-model="editing_product.product_buffer_green"
                                                :step="0.1"
                                                min="0.1"
                                                :disabled="!$store.getters['auth/canEditProduction']()"
                                            ></form-input-fraction>
                                            <span class="input-group-text">人日</span>
                                        </div>
                                    </div>
                                </div>

                                <h5>製作図</h5>
                                <div class="row mb-3">
                                    <form-upload-multiple
                                        v-model="editing_product.production_drawings"
                                        :upload_dir="UploadService.PRODUCT_PRODUCTION"
                                        :is_editable="$store.getters['auth/canEditProduction']()"
                                    ></form-upload-multiple>
                                </div>

                                <h5>製作図用規格</h5>
                                <table class="table">
                                    <tbody>
                                        <template v-for="production_spec_value in editing_product.production_spec_values" :key="production_spec_value">
                                                <tr>
                                                    <td class="align-middle">
                                                        {{ specLabelDisplay(production_spec_value.production_spec_label.spec_label.spec_label_id) }} <span class="small text-secondary ms-1">※ {{ specLabelInputTypeDisplay(production_spec_value.production_spec_label.spec_label.spec_label_id) }}</span>
                                                    </td>
                                                    <td class="align-middle">
                                                        <div class="input-group">
                                                            <span
                                                                v-if="production_spec_value.production_spec_label.spec_prefix.spec_prefix_id"
                                                                class="input-group-text"
                                                            >
                                                                {{ specPrefixDisplay(production_spec_value.production_spec_label.spec_prefix.spec_prefix_id) }}
                                                            </span>

                                                            <!-- 規格値 -->
                                                            <!-- 文字列 -->
                                                            <form-input
                                                                v-if="Number(production_spec_value.production_spec_label.spec_label.input_type) === InputType.STRING"
                                                                v-model="production_spec_value.spec_value"
                                                                maxlength="191"
                                                                :disabled="!$store.getters['auth/canEditProduction']()"
                                                            ></form-input>
                                                            <!-- 整数 -->
                                                            <form-input-number
                                                                v-if="Number(production_spec_value.production_spec_label.spec_label.input_type) === InputType.INTEGER"
                                                                v-model="production_spec_value.spec_value"
                                                                min="0"
                                                                :disabled="!$store.getters['auth/canEditProduction']()"
                                                            ></form-input-number>
                                                            <!-- 数値(少数1位) -->
                                                            <form-input-fraction
                                                                v-if="Number(production_spec_value.production_spec_label.spec_label.input_type) === InputType.DECIMAL_1"
                                                                v-model="production_spec_value.spec_value"
                                                                :disabled="!$store.getters['auth/canEditProduction']()"
                                                            ></form-input-fraction>
                                                            <!-- 数値(少数2位) -->
                                                            <form-input-fraction
                                                                v-if="Number(production_spec_value.production_spec_label.spec_label.input_type) === InputType.DECIMAL_2"
                                                                v-model="production_spec_value.spec_value"
                                                                :step=".01"
                                                                :disabled="!$store.getters['auth/canEditProduction']()"
                                                            ></form-input-fraction>
                                                            <span
                                                                v-if="production_spec_value.production_spec_label.spec_suffix.spec_suffix_id"
                                                                class="input-group-text"
                                                            >{{ specSuffixDisplay(production_spec_value.production_spec_label.spec_suffix.spec_suffix_id) }}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="modal-footer">
                                <button-exec
                                    text="完了"
                                    icon=""
                                    color="btn-secondary"
                                    @click="closeProductProductionModal(editing_product)"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- 価格・掛率情報モーダル -->
    <div v-if="productPriceRateModal">
        <transition name="fade">
            <div v-if="productPriceRateModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    価格・掛率情報 ({{ product_group_revision.product_group_code }}-{{ editing_product.product_code }})
                                </h4>
                                <button-cancel-close
                                    text=""
                                    @click="cancelProductPriceRateModal();"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="入力をキャンセル"
                                ></button-cancel-close>
                            </div>
                            <div class="modal-body">
                                <div class="row align-items-end mb-3">
                                    <div class="form-group col-md-8">
                                        <label>掛率記号</label>
                                        <form-select
                                            v-model="selected_wholesale_rate_id"
                                            :options="select_wholesale_rates"
                                            empty_option="-- 選択 --"
                                            @change="setWholesaleRate()"
                                            :disabled="!$store.getters['auth/canEditPrice']()"
                                        ></form-select>
                                    </div>
                                </div>
                                <table class="table table-fixed table-bordered text-center mb-1 w-30">
                                    <tbody>
                                        <tr>
                                            <th class="table-light w-50">販売価格</th>
                                            <td class="w-50">{{ editing_product.regular_price_display }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table table-fixed table-bordered text-center mb-1 w-60">
                                    <tbody>
                                        <tr>
                                            <th class="table-light w-25">代理店掛率</th>
                                            <td class="w-25">{{ wholesale_rate.distributor_rate ? wholesale_rate.distributor_rate + '%' : '-' }}</td>
                                            <th class="table-light w-25">代理店価格</th>
                                            <td class="w-25">¥ {{ distributor_price }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table table-fixed table-bordered text-center mb-1 w-60">
                                    <tbody>
                                        <tr>
                                            <th class="table-light w-25">指定代理店掛率</th>
                                            <td class="w-25">{{ wholesale_rate.designated_distributor_rate ? wholesale_rate.designated_distributor_rate + '%' : '-' }}</td>
                                            <th class="table-light w-25">指定代理店価格</th>
                                            <td class="w-25">¥ {{ designated_distributor_price }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table table-fixed table-bordered text-center mb-1 w-60">
                                    <tbody>
                                        <tr>
                                            <th class="table-light w-25">グループ会社掛率</th>
                                            <td class="w-25">{{ wholesale_rate.group_company_rate ? wholesale_rate.group_company_rate + '%' : '-' }}</td>
                                            <th class="table-light w-25">グループ会社価格</th>
                                            <td class="w-25">¥ {{ group_company_price }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table table-fixed table-bordered text-center mb-1 w-60">
                                    <tbody>
                                        <tr>
                                            <th class="table-light w-25">工場出し掛率</th>
                                            <td class="w-25">{{ wholesale_rate.shipping_rate ? wholesale_rate.shipping_rate + '%' : '-' }}</td>
                                            <th class="table-light w-25">工場出し価格</th>
                                            <td class="w-25">¥ {{ shipping_price }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table table-fixed table-bordered text-center mb-1 w-60">
                                    <tbody>
                                        <tr>
                                            <th class="table-light w-25">工場掛率</th>
                                            <td class="w-25">{{ wholesale_rate.factory_rate ? wholesale_rate.factory_rate + '%' : '-' }}</td>
                                            <th class="table-light w-25">工場価格</th>
                                            <td class="w-25">¥ {{ factory_price }}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h4 class="mt-3">商社別情報</h4>
                                <table class="table table-fixed table-bordered text-center mb-3" v-if="editing_product.retailers.length">
                                    <thead>
                                        <tr>
                                            <th class="table-light w-20">商社</th>
                                            <th class="table-light w-10">販売</th>
                                            <th class="table-light w-15">製品コード</th>
                                            <th class="table-light w-25">製品名</th>
                                            <th class="table-light w-15">商社掛率</th>
                                            <th class="table-light w-15">商社価格</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="product_retailer in editing_product.retailers" :key="product_retailer">
                                            <!-- リレーションで呼ぶと削除済も取得されるので、除外 -->
                                            <tr v-if="!product_retailer.retailer.deleted_timestamp">
                                                <td>{{ product_retailer.retailer.retailer_name }}</td>
                                                <td class="nowrap">
                                                    <form-checkbox
                                                        v-model="product_retailer.is_retailer_selling"
                                                        option_label="販売"
                                                        :disabled="!$store.getters['auth/canEditPrice']()"
                                                    ></form-checkbox>
                                                </td>
                                                <td>
                                                    <form-input
                                                        v-model="product_retailer.retailer_product_code"
                                                        maxlength="191"
                                                        :disabled="!$store.getters['auth/canEditPrice']()"
                                                    ></form-input>
                                                </td>
                                                <td>
                                                    <form-input
                                                        v-model="product_retailer.retailer_product_name"
                                                        maxlength="191"
                                                        :disabled="!$store.getters['auth/canEditPrice']()"
                                                    ></form-input>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <form-input-number
                                                            v-model="product_retailer.retailer_rate"
                                                            min="0"
                                                            @change="setRetailerPrice(product_retailer)"
                                                            :disabled="!$store.getters['auth/canEditPrice']()"
                                                        ></form-input-number>
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <form-input-number
                                                            v-model="product_retailer.retailer_price"
                                                            min="1"
                                                            :disabled="!$store.getters['auth/canEditPrice']()"
                                                        ></form-input-number>
                                                        <span class="input-group-text">円</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                                <alert-no-Record v-else class="mb-3"></alert-no-Record>
                            </div>
                            <div class="modal-footer">
                                <button-exec
                                    text="完了"
                                    icon=""
                                    color="btn-secondary"
                                    @click="closeProductPriceRateModal()"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- 構成品情報モーダル -->
    <div v-if="productComponentModal">
        <transition name="fade">
            <div v-if="productComponentModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    構成品情報 ({{ product_group_revision.product_group_code }}-{{ editing_product.product_code }})
                                </h4>
                                <button-cancel-close
                                    text=""
                                    @click="cancelProductComponentModal();"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="入力をキャンセル"
                                ></button-cancel-close>
                            </div>
                            <div class="modal-body">
                                <table class="table" v-if="editing_product.components.length > 0">
                                    <thead>
                                        <tr class="table-dark">
                                            <th></th>
                                            <th class="text-center align-middle">製品コード</th>
                                            <th class="text-center align-middle">製品名</th>
                                            <th class="text-center align-middle">規格</th>
                                            <th class="text-center align-middle">価格</th>
                                            <th class="text-center align-middle">入数</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <draggable
                                        v-model="editing_product.components"
                                        item-key="component"
                                        handle=".handle"
                                        tag="tbody"
                                    >
                                        <template #item="{element, index}">
                                            <tr>
                                                <td class="align-middle col-2 text-center" :class="{'handle': $store.getters['auth/canEditBasic']()}">
                                                    <i class="bi bi-chevron-bar-expand"></i>
                                                </td>
                                                <td class="align-middle">
                                                    <read-only
                                                        :text="element.child_product.joined_product_code"
                                                    ></read-only>
                                                </td>
                                                <td class="align-middle">
                                                    <read-only
                                                        :text="element.child_product.product_group_name"
                                                    ></read-only>
                                                </td>
                                                <td class="align-middle">
                                                    <read-only
                                                        :text="element.child_product.joined_standard_spec_values"
                                                    ></read-only>
                                                </td>
                                                <td class="align-middle">
                                                    <read-only
                                                        :text="element.child_product.regular_price_display"
                                                        class="text-end"
                                                    ></read-only>
                                                </td>
                                                <td class="align-middle">
                                                    <form-input-number
                                                        v-model="element.component_count"
                                                        min="0"
                                                        required="required"
                                                        :disabled="!$store.getters['auth/canEditBasic']()"
                                                    ></form-input-number>
                                                </td>
                                                <td class="align-middle">
                                                    <button-exec
                                                        text=""
                                                        icon="bi-trash"
                                                        @click="removeProductComponent(index)"
                                                        :disabled="!$store.getters['auth/canEditBasic']()"
                                                    ></button-exec>
                                                </td>
                                            </tr>
                                        </template>
                                    </draggable>
                                </table>
                                            <!-- <label>番号</label>
                                            <form-input-number
                                                v-model="element.sequence"
                                                min="0"
                                                required="required"
                                                :disabled="!$store.getters['auth/canEditBasic']()"
                                            ></form-input-number> -->
                                <div class="mb-3">
                                    <button-exec
                                        text="構成品を追加"
                                        icon="bi-plus"
                                        color="btn-outline-primary"
                                        @click="openSearchComponentModal()"
                                        :disabled="!$store.getters['auth/canEditBasic']()"
                                    ></button-exec>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button-exec
                                    text="完了"
                                    icon=""
                                    color="btn-secondary"
                                    @click="closeProductComponentModal(editing_product)"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- 構成品検索モーダル -->
    <div v-if="searchComponentModal">
        <transition name="fade">
            <div v-if="searchComponentModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    構成品情報 ({{ product_group_revision.product_group_code }}-{{ editing_product.product_code }}) 追加
                                </h4>
                            </div>
                            <div class="modal-body">
                                <div class="row align-items-end mb-3">
                                    <div class="form-group col-md-10">
                                        <label>製品コード</label>
                                        <form-input
                                            v-model="condition.product_code"
                                            maxlength="191"
                                        ></form-input>
                                    </div>
                                    <div class="col-md-5">
                                        <button-search
                                            @click="searchComponent()"
                                            :disabled="component_loading"
                                        ></button-search>
                                    </div>
                                </div>

                                <inline-loader v-if="component_loading"></inline-loader>
                                <template v-else>
                                    <template v-if="searched_component">
                                        <template v-if="child_products.length">
                                            <table class="table">
                                                <thead>
                                                    <tr class="table-dark">
                                                        <th class="text-center">製品コード</th>
                                                        <th class="text-center">製品名</th>
                                                        <th class="text-center">規格</th>
                                                        <th class="text-center">販売価格</th>
                                                        <th class="text-center"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="child_product in child_products" :key="child_product.child_product_id">
                                                        <td class="align-middle text-center">{{ child_product.joined_product_code }}</td>
                                                        <td class="align-middle text-center">{{ child_product.product_group_name }}</td>
                                                        <td class="align-middle text-center">{{ child_product.joined_standard_spec_values }}</td>
                                                        <td class="align-middle text-center">{{ child_product.regular_price_display }}</td>
                                                        <td class="align-middle text-center">
                                                            <button-exec
                                                                text="追加"
                                                                color="btn-primary"
                                                                :disabled="!$store.getters['auth/canEditBasic']()"
                                                                @click="addProductComponent(child_product)"
                                                            ></button-exec>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <paginator :meta="paginator" @move="searchComponent"></paginator>
                                        </template>
                                        <alert-no-record v-else></alert-no-record>
                                    </template>
                                </template>
                            </div>
                            <div class="modal-footer">
                                <button-exec
                                    text="閉じる"
                                    icon=""
                                    color="btn-secondary"
                                    @click="closeSearchComponentModal()"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- 製品追加モーダル -->
    <div v-if="addProductModal">
        <transition name="fade">
            <div v-if="addProductModal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    製品追加
                                </h4>
                            </div>
                            <div class="modal-body">
                                <div class="row align-items-start mb-3">
                                    <div class="col-md-8">
                                        <label>公開区分</label>
                                        <form-select
                                            v-model="additional_product.public_type"
                                            :options="public_types"
                                            :required="true"
                                            empty_option="-- 選択 --"
                                        ></form-select>
                                    </div>
                                    <div class="col-md-8">
                                        <label>ステータス</label>
                                        <form-select
                                            v-model="additional_product.availability"
                                            :options="availabilities"
                                            :required="true"
                                            empty_option="-- 選択 --"
                                        ></form-select>
                                    </div>
                                    <div class="col-md-8">
                                        <label>取引停止日</label>
                                        <form-input-date
                                            v-model="additional_product.pending_date"
                                        ></form-input-date>
                                    </div>
                                </div>
                                <div class="row align-items-end mb-3">
                                    <div class="form-group col-md-12">
                                        <label>製品コード</label>
                                        <div class="input-group">
                                            <span class="input-group-text">{{ product_group_revision.product_group_code }}-</span>
                                            <form-input
                                                v-model="additional_product.product_code"
                                                required="required"
                                                maxlength="191"
                                            ></form-input>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label>販売価格</label>
                                        <div class="input-group">
                                            <div class="input-group-text">
                                                <form-checkbox
                                                    v-model="additional_product.is_each_time"
                                                    option_label="都度見積"
                                                ></form-checkbox>
                                            </div>
                                            <form-input-number
                                                v-model="additional_product.regular_price"
                                                min="0"
                                            ></form-input-number>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-end mb-3">
                                    <div class="form-group col-md-12">
                                        <label>Parts No.</label>
                                        <form-input
                                            v-model="additional_product.parts_number"
                                            maxlength="10"
                                        ></form-input>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label>製品型式</label>
                                        <form-input
                                            v-model="additional_product.model_number"
                                            maxlength="191"
                                        ></form-input>
                                    </div>
                                </div>
                                <div class="row align-items-end mb-3">
                                    <div class="form-group col-md-12">
                                        <label>生産区分</label>
                                        <form-select
                                            v-model="additional_product.production_type"
                                            :options="production_types"
                                            empty_option="-- 選択 --"
                                        ></form-select>
                                    </div>
                                </div>
                                <div class="row align-items-end mb-3">
                                    <div class="form-group col-md-12">
                                        <label>項目1</label>
                                        <form-input
                                            v-model="additional_product.field_1"
                                            maxlength="191"
                                        ></form-input>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label>項目2</label>
                                        <form-input
                                            v-model="additional_product.field_2"
                                            maxlength="191"
                                        ></form-input>
                                    </div>
                                </div>
                                <div class="row align-items-end mb-3">
                                    <div class="form-group col-md-12">
                                        <label>項目3</label>
                                        <form-input
                                            v-model="additional_product.field_3"
                                            maxlength="191"
                                        ></form-input>
                                    </div>
                                </div>
                                <div class="text-center mt-4 mb-2">
                                    <button-exec
                                        color="btn-primary"
                                        text="追加"
                                        icon=""
                                        :disabled="!$store.getters['auth/canEditBasic']()"
                                        @click="addProduct()"
                                    ></button-exec>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button-exec
                                    text="閉じる"
                                    icon=""
                                    color="btn-secondary"
                                    @click="closeAddProductModal()"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <confirm-dialog ref="confirm_remove_subgroup" @ok="removeSubgroup()">
        <p>サブグループを削除してよろしいですか？各サブグループにて登録されたデータ（製品情報等）も同時に削除されます。ご注意ください。</p>
    </confirm-dialog>
    <confirm-dialog ref="confirm_remove_catalog_spec_label" @ok="removeCatalogSpecLabel()">
        <p>規格項目を削除してよろしいですか？各製品にてこの規格項目に登録されたデータも同時に削除されます。ご注意ください。</p>
    </confirm-dialog>
    <confirm-dialog ref="confirm_remove_standard_spec_label" @ok="removeStandardSpecLabel()">
        <p>規格項目を削除してよろしいですか？各製品にてこの規格項目に登録されたデータも同時に削除されます。ご注意ください。</p>
    </confirm-dialog>
    <confirm-dialog ref="confirm_remove_production_spec_label" @ok="removeProductionSpecLabel()">
        <p>規格項目を削除してよろしいですか？各製品にてこの規格項目に登録されたデータも同時に削除されます。ご注意ください。</p>
    </confirm-dialog>

    <confirm-dialog ref="confirm_remove_product" @ok="removeProduct()">
        <p>製品を削除してよろしいですか？</p>
    </confirm-dialog>

    <confirm-dialog ref="confirm_remove_all" @ok="removeAll()">
        <p>製品グループ「{{product_group_revision.product_group_name}}」を削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import draggable from 'vuedraggable';
import ProductGroupRevision from '@/models/entities/product-group-revision';
import Product from '@/models/entities/product';
import ProductGroup from '@/models/entities/product-group';
import ProductRetailerRevision from '@/models/entities/product-retailer-revision';
import ProductRevision from '@/models/entities/product-revision';
import Retailer from '@/models/entities/retailer';
import SpecLabel from '@/models/entities/spec-label';
import SpecPrefix from '@/models/entities/spec-prefix';
import SpecSuffix from '@/models/entities/spec-suffix';
import WholesaleRate from '@/models/entities/wholesale-rate';
import ProductGroupMovieRevision from '@/models/entities/product-group-movie-revision';
import ProductGroupCatalogPageRevision from '@/models/entities/product-group-catalog-page-revision';
import ProductSubgroupCatalogPageRevision from '@/models/entities/product-subgroup-catalog-page-revision';
import ProductGroupWorkflowRevision from '@/models/entities/product-group-workflow-revision.js';
import ProductSubgroupRevision from '@/models/entities/product-subgroup-revision';
import ProductCatalogSpecLabelRevision from '@/models/entities/product-catalog-spec-label-revision';
import ProductStandardSpecLabelRevision from '@/models/entities/product-standard-spec-label-revision';
import ProductProductionSpecLabelRevision from '@/models/entities/product-production-spec-label-revision';
import ProductCatalogSpecValueRevision from '@/models/entities/product-catalog-spec-value-revision';
import ProductStandardSpecValueRevision from '@/models/entities/product-standard-spec-value-revision';
import ProductProductionSpecValueRevision from '@/models/entities/product-production-spec-value-revision';
import ProductComponentRevision from '@/models/entities/product-component-revision';
import ApprovedStatus from '@/models/enums/product-group-workflow/approved-status';
import Availability from '@/models/enums/availability';
import InputType from '@/models/enums/input-type';
import IsOnCatalog from '@/models/enums/is-on-catalog';
import IsPriceOnCatalog from '@/models/enums/is-on-catalog';
import SupplyType from '@/models/enums/supply-type';
import PublicType from '@/models/enums/public-type';
import PublishedStatus from '@/models/enums/published-status';
import ProductionType from '@/models/enums/production-type';
import UploadService from '@/models/services/upload-service';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import PageTitle from '@/components/PageTitle.vue';
import ButtonBack from '@/components/buttons/ButtonBack';
import ButtonCancelClose from '@/components/buttons/ButtonCancelClose';
import ButtonExec from '@/components/buttons/ButtonExec';
import ButtonExecCreate from '@/components/buttons/ButtonExecCreate';
import ButtonExecDelete from '@/components/buttons/ButtonExecDelete';
import ButtonGoView from '@/components/buttons/ButtonGoView';
import ButtonGoUpdate from '@/components/buttons/ButtonGoUpdate';
import ButtonSearch from '@/components/buttons/ButtonSearch';
import FormCheckbox from '@/components/forms/FormCheckbox';
import FormInput from '@/components/forms/FormInput';
import FormInputDate from '@/components/forms/FormInputDate';
import FormInputFraction from '@/components/forms/FormInputFraction';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormSelect from '@/components/forms/FormSelect';
import FormTextarea from '@/components/forms/FormTextarea';
import FormUploadMultiple from '@/components/forms/FormUploadMultiple';
import ReadOnly from '@/components/forms/ReadOnly';
import AlertNoRecord from '@/components/notice/AlertNoRecord';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Paginator from '@/components/tools/Paginator';

export default {
    name: 'ProductGroupEdit',
    components: {
        draggable,
        PageTitle,
        AlertNoRecord,
        ButtonBack,
        ButtonCancelClose,
        ButtonExec,
        ButtonExecCreate,
        ButtonExecDelete,
        ButtonGoView,
        ButtonGoUpdate,
        ButtonSearch,
        ConfirmDialog,
        FormCheckbox,
        FormInput,
        FormInputDate,
        FormInputFraction,
        FormInputNumber,
        FormSelect,
        FormTextarea,
        FormUploadMultiple,
        InlineLoader,
        Paginator,
        ReadOnly,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            // 部分ローダー
            loading: 0,
            component_loading: 0,
            // uploading: false,

            //パスパラメータ
            product_group_id: parseInt(this.$route.params.id, 10),

            //送信する値
            product_group_revision: new ProductGroupRevision(),

            // データ格納用
            spec_labels: [],
            spec_prefixes: [],
            spec_suffixes: [],
            retailers: [],
            wholesale_rates: [],
            child_products: [],

            // 離脱アラート用
            is_saved: true,

            //編集用
            target_index: null,
            merged_product_group_code: null,
            add_spec_label_id: null,
            editing_product: new ProductRevision(),
            editing_subgroup: new ProductSubgroupRevision(),
            additional_product: new ProductRevision(),
            editing_subgroup_index: 0,
            selected_wholesale_rate_id: null,

            //選択肢
            availabilities: Availability.inputOptions(),
            supply_types: SupplyType.options(),
            public_types: PublicType.options(),
            production_types: ProductionType.options(),
            select_workflow_approved_status: ApprovedStatus.options(),
            select_catalogs: [],
            select_spec_labels: [],
            select_spec_prefixes: [],
            select_spec_suffixes: [],
            select_wholesale_rates: [],

            //検索用
            condition: {
                my_product_id: null,
                product_code: null,
                page: 1,
            },
            searched_component: false,
            //ページネーター
            paginator: {},

            //Enums
            Availability: Availability,
            ApprovedStatus: ApprovedStatus,
            InputType: InputType,
            IsOnCatalog: IsOnCatalog,
            IsPriceOnCatalog: IsPriceOnCatalog,
            PublishedStatus: PublishedStatus,
            UploadService: UploadService,
            PublicType: PublicType,

            //modals
            workflowModal: false,
            catalogSpecModal: false,
            standardSpecModal: false,
            productionSpecModal: false,
            is_shown_catalog_drawing: true,
            is_shown_standard_drawing: false,
            modal_catalog_drawing: null,
            modal_standard_drawing: null,
            sortSubgroupModal: false,

            addProductModal: false,

            productBasicModal: false,
            old_product: {},
            productCatalogPageModal: false,
            productCatalogDrawingModal: false,
            productStandardDrawingModal: false,
            productCatalogStandardModal: false,
            productProductionModal: false,
            productPriceRateModal: false,

            productComponentModal: false,
            searchComponentModal: false,

            // 1分ごとに排他ロック継続処理
            heartbeat: null,
        }
    },
    mounted() {
        this.loading++;

        // どの権限も持たない場合はエラー
        if (!this.$store.getters['auth/canEditAny']()) {
            this.showErrorMessage('アクセス権限がありません');
            this.$router.push({name: 'ProductGroupList'});
            return;
        }

        //排他制御（初期表示）
        this.$http.get(`/product-group/${this.product_group_id}/lock`)
        .then(response => {
            let result = response.data.result;
            let message = response.data.message;

            if (!result) {
                this.showErrorMessage(message);
                this.$router.go(-1); //一つ前の画面へ戻す
                return;
            }

            this.fetchLatestProductGroupRevision(),
            this.fetchSelectCatalogs();
            this.fetchSelectSpecLabels();
            this.fetchSelectSpecPrefixes();
            this.fetchSelectSpecSuffixes();
            this.fetchSelectWholesaleRates();
            this.fetchRetailers();
            this.setHeartBeat();
        })
        .finally(() => {
            this.loading--;
        });
    },
    beforeUnmount () {
        //他のページに遷移したら排他ロック継続処理を停止
        this.clearHeartBeat();
        this.releaseEditRock();
    },
    beforeRouteLeave (to, from, next) {
        if (!this.is_saved) {
            const answer = window.confirm('保存されていない変更があります。よろしいですか？');
            if (answer) {
                next()
            } else {
                next(false)
            }
        } else {
            next();
        }
    },
    created () {
        window.addEventListener("beforeunload", this.confirmSave);
    },
    unmounted () {
        window.removeEventListener("beforeunload", this.confirmSave);
    },
    computed: {
        merged_product_group_name() {
            if (!this.product_group_revision.merged_product_group) {
                return null;
            }
            return this.product_group_revision.merged_product_group.product_group_name;
        },
        //選択された掛率エンティティを抽出
        wholesale_rate() {
            let wholesale_rate = this.wholesale_rates.find(wholesale_rate => wholesale_rate.wholesale_rate_id === this.selected_wholesale_rate_id);

            if (this.$helper.isNud(wholesale_rate)) {
                wholesale_rate = new WholesaleRate();
            }

            return wholesale_rate;
        },
        //編集中の製品の販売価格に代理店掛率をかけたもの
        distributor_price() {
            let regular_price = parseInt(this.editing_product.regular_price, 10);  //切り捨て
            let rate = this.wholesale_rate.distributor_rate;

            if (!regular_price) {
                return '未入力'
            }

            if (!rate) {
                return regular_price.toLocaleString();
            }

            let distributor_price = Math.floor(regular_price * (rate / 100)); //切り捨て
            return distributor_price.toLocaleString();
        },
        //編集中の製品の販売価格に指定代理店掛率をかけたもの
        designated_distributor_price() {
            let regular_price = parseInt(this.editing_product.regular_price, 10);
            let rate = this.wholesale_rate.designated_distributor_rate;

            if (!regular_price) {
                return '未入力'
            }

            if (!rate) {
                return regular_price.toLocaleString();
            }

            let designated_distributor_price = Math.floor(regular_price * (rate / 100)); //切り捨て
            return designated_distributor_price.toLocaleString();
        },
        //編集中の製品の販売価格にグループ会社掛率をかけたもの
        group_company_price() {
            let regular_price = parseInt(this.editing_product.regular_price, 10);
            let rate = this.wholesale_rate.group_company_rate;

            if (!regular_price) {
                return '未入力'
            }

            if (!rate) {
                return regular_price.toLocaleString();
            }

            let group_company_price = Math.floor(regular_price * (rate / 100)); //切り捨て
            return group_company_price.toLocaleString();
        },
        //編集中の製品の販売価格に工場出し掛率をかけたもの
        shipping_price() {
            let regular_price = parseInt(this.editing_product.regular_price, 10);
            let rate = this.wholesale_rate.shipping_rate;

            if (!regular_price) {
                return '未入力'
            }

            if (!rate) {
                return regular_price.toLocaleString();
            }

            let shipping_price = Math.floor(regular_price * (rate / 100)); //切り捨て
            return shipping_price.toLocaleString();
        },
        //編集中の製品の販売価格に工場掛率をかけたもの
        factory_price() {
            let regular_price = parseInt(this.editing_product.regular_price, 10);
            let rate = this.wholesale_rate.factory_rate;

            if (!regular_price) {
                return '未入力'
            }

            if (!rate) {
                return regular_price.toLocaleString();
            }

            let factory_price = Math.floor(regular_price * (rate / 100)); //切り捨て
            return factory_price.toLocaleString();
        },
        // 製作情報閲覧権限（隠してしまうと値が送られず、その箇所のデータが削除されてしまう問題
        // isShownProduction() {
        //     if (this.login_user.roles.includes(Role.VIEW_PRODUCTION)) {
        //         return true;
        //     }
        //     return false;
        // },
    },
    methods: {
        /**
         * api 取得系
         */
        //製品グループリビジョンより最新リビジョンのデータを取得
        fetchLatestProductGroupRevision() {
            this.loading++;

            this.$http.get(`/product-group/${this.product_group_id}/latest`)
            .then(response => {
                this.product_group_revision = new ProductGroupRevision(response.data);
                //サブグループは必ず一つは存在する（インポート等でも、サブグループが一つも存在しないはありえない想定、なければエラー起こる）
                // if (this.product_group_revision.subgroups.length) {
                    this.editing_subgroup = this.product_group_revision.subgroups[0];
                // }
                //merged_product_group_codeをセット
                if (!this.$helper.isNud(this.product_group_revision.merged_product_group)){
                    this.merged_product_group_code = this.product_group_revision.merged_product_group.product_group_code;
                }
            })
            .finally(() => {
                this.loading--;
                this.is_saved = true; //初期表示のため
            });
        },
        //カタログマスタより選択肢を取得
        fetchSelectCatalogs() {
            this.loading++;

            this.$http.get('/catalog')
            .then(response => {
                for (let row of response.data) {
                    this.select_catalogs.push({key: row.catalog_id, label: row.catalog_name});
                }
            })
            .finally(() => {
                this.loading--;
                this.is_saved = true; //初期表示のため
            });
        },
        //規格項目マスタより選択肢を取得
        fetchSelectSpecLabels() {
            this.loading++;

            this.$http.get('/spec-label')
            .then(response => {
                for (let row of response.data) {
                    this.spec_labels.push(new SpecLabel(row));
                    this.select_spec_labels.push({key: row.spec_label_id, label: row.label_name});
                }
            })
            .finally(() => {
                this.loading--;
                this.is_saved = true; //初期表示のため
            });
        },
        //規格前置単位マスタより選択肢を取得
        fetchSelectSpecPrefixes() {
            this.loading++;

            this.$http.get('/spec-prefix')
            .then(response => {
                for (let row of response.data) {
                    this.spec_prefixes.push(new SpecPrefix(row));
                    this.select_spec_prefixes.push({key: row.spec_prefix_id, label: row.prefix_name});
                }
            })
            .finally(() => {
                this.loading--;
                this.is_saved = true; //初期表示のため
            });
        },
        //規格後置単位マスタより選択肢を取得
        fetchSelectSpecSuffixes() {
            this.loading++;

            this.$http.get('/spec-suffix')
            .then(response => {
                for (let row of response.data) {
                    this.spec_suffixes.push(new SpecSuffix(row));
                    this.select_spec_suffixes.push({key: row.spec_suffix_id, label: row.suffix_name});
                }
            })
            .finally(() => {
                this.loading--;
                this.is_saved = true; //初期表示のため
            });
        },
        //掛率マスタより選択肢を取得
        fetchSelectWholesaleRates() {
            this.loading++;

            this.$http.get('/wholesale-rate')
            .then(response => {
                for (let row of response.data) {
                    this.wholesale_rates.push(new WholesaleRate(row));
                    this.select_wholesale_rates.push({key: row.wholesale_rate_id, label: row.rate_mark});
                }
            })
            .finally(() => {
                this.loading--;
                this.is_saved = true; //初期表示のため
            });
        },
        //商社マスタ取得
        fetchRetailers() {
            this.loading++;

            this.$http.get('/retailer')
            .then(response => {
                for (let row of response.data) {
                    this.retailers.push(new Retailer(row));
                }
            })
            .finally(() => {
                this.loading--;
                this.is_saved = true; //初期表示のため
            });
        },

        /**
         * 
         */
        //メインデータ再取得
        reloadData () {
            this.fetchLatestProductGroupRevision();
            this.editing_subgroup_index = 0;
        },
        //編集するINDEX番号をSET
        setTargetIndex(index) {
            this.target_index = index;
        },
        unsetTargetIndex() {
            this.target_index = null;
        },

        /**
         * 保存処理系
         */
        confirmSave (event) {
            if (!this.is_saved) {
                event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
            }
        },
        //保存して公開
        saveAndPublish() {
            let errors = this.validateSave();
            //バリデートエラーがある場合中断
            if (errors.length) {
                this.showErrorMessage('入力エラー', errors.join("\n"));
                return;
            }

            this.setProductPriority();

            this.startScreenLoading();

            // 排他ロック継続処理を停止
            this.clearHeartBeat();

            this.$http.post(`/product-group/${this.product_group_id}/publish`, this.product_group_revision)
            .then(() => {
                this.showMessage('保存して公開しました');
                //再取得する（リビジョン番号が新規となる・新規登録項目にprimary_keyをSetしたい）
                this.reloadData();
            })
            .finally(() => {
                this.endScreenLoading();
                // 排他ロック継続処理開始
                this.setHeartBeat();
            });
        },

        //下書きとして保存
        saveAsDraft() {
            let errors = this.validateSave();
            //バリデートエラーがある場合中断
            if (errors.length) {
                this.showErrorMessage('入力エラー', errors.join("\n"));
                return;
            }
            this.setProductPriority();
            this.startScreenLoading();

            // 排他ロック継続処理を停止
            this.clearHeartBeat();

            this.$http.put(`/product-group/${this.product_group_id}/latest`, this.product_group_revision)
            .then(() => {
                this.showMessage('下書き保存しました');
                this.is_saved = true;
                //再取得する（リビジョン番号が新規となる・新規登録項目にprimary_keyをSetしたい）
                this.reloadData();
            })
            .finally(() => {
                this.endScreenLoading();
                // 排他ロック継続処理開始
                this.setHeartBeat();
            });
        },

        /**
         * 製品グループ処理系
         */

        //規格変更履歴
        addWorkflow() {
            this.product_group_revision.workflows.push(new ProductGroupWorkflowRevision());
        },
        removeWorkflow(index) {
            this.product_group_revision.workflows.splice(index, 1);
        },
        //対象製品グループカタログ掲載を追加
        addCatalogPage() {
            this.product_group_revision.catalog_pages.push(new ProductGroupCatalogPageRevision());
        },
        //対象製品グループカタログ掲載を削除
        removeCatalogPage(index) {
            this.product_group_revision.catalog_pages.splice(index, 1);
        },
        //カタログ合成マスタ選択後、マスタ（公開の）をセット
        setMergedProductGroup() {
            if (!this.merged_product_group_code) {
                this.product_group_revision.merged_product_group = null;
                return;
            }
            if(!this.checkHalfEnNumPlus(this.merged_product_group_code)) {
                this.showErrorMessage('入力エラー', '[カタログ]合成（親）製品グループコードは半角英数記号で入力してください');
                return;
            }

            this.startScreenLoading();

            this.$http.get(`/product-group/search`, {
                params: {
                    product_group_code: this.merged_product_group_code,
                },
            })
            .then(response => {
                if (!response.data) {
                    this.showErrorMessage('入力エラー', '[カタログ]合成（親）の入力した製品グループコードが存在しないか、下書きのみの製品グループです。設定し直してください。');
                    this.product_group_revision.merged_product_group = null;
                    this.merged_product_group_code = '';
                } else {
                    this.product_group_revision.merged_product_group = new ProductGroup(response.data);
                }
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //対象動画を追加
        addMovie() {
            this.product_group_revision.movies.push(new ProductGroupMovieRevision());
        },
        //対象動画を削除
        removeMovie(index) {
            this.product_group_revision.movies.splice(index, 1);
        },

        //規格項目設定 規格名
        // getSelectedSpecLabelName(spec_label_id) {
        //         let spec_label = this.spec_labels.find(spec_label => spec_label.spec_label_id === spec_label_id);
        //         if (!spec_label) {
        //             return null;
        //         }
        //         return spec_label.label_name;
        // },

        //製品グループ全体を削除
        removeAll() {
            // 排他ロック継続処理を停止
            this.clearHeartBeat();

            this.startScreenLoading();

            if (this.product_group_revision.public_type !== PublicType.INVALID) {
                this.showErrorMessage('公開区分が無効の場合のみ削除できます');
                this.endScreenLoading();
                return;
            }

            this.$http.delete(`/product-group/${this.product_group_id}`)
            .then(() => {
                this.is_saved = true; //離脱確認モーダル回避
                this.showMessage('製品グループを削除しました');
                this.$router.push({name: 'ProductGroupList'});
            })
            .catch(() => {
                this.setHeartBeat();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },

        /**
         * 製品サブグループ系
         */
        //タブでサブグループ切り替え 現在のsubgroupをsetする
        setEditSubgroup(index) {
            //サブグループ名が空の場合は切り替えさせない
            let null_of_subgroup_names = this.product_group_revision.subgroups.filter(subgroup => subgroup.product_subgroup_name === null || subgroup.product_subgroup_name === '');
            if (null_of_subgroup_names.length > 0) {
                this.showErrorMessage('切替エラー', 'サブグループ名は空白にできません');
                return;
            }
            this.editing_subgroup = this.product_group_revision.subgroups[index];
            this.editing_subgroup_index = index;
        },
        //サブグループを追加する
        addSubgroup() {
            let subgroup = new ProductSubgroupRevision();
            let new_index = this.product_group_revision.subgroups.length;
            subgroup.product_subgroup_name = '新しいサブグループ';
            subgroup.priority = new_index + 1;
            this.product_group_revision.subgroups.push(subgroup);

            //サブグループを開く
            this.setEditSubgroup(new_index);
        },
        //サブグループを削除する
        removeSubgroup() {
            if (null === this.target_index) {
                return;
            }
            //削除
            this.product_group_revision.subgroups.splice(this.target_index, 1);
            this.unsetTargetIndex();
            //先頭のサブグループを開く
            this.setEditSubgroup(0);
        },
        //サブグループの順番をつける
        setSubgroupPriority() {
            this.product_group_revision.subgroups.forEach((subgroup, index) => {
                subgroup.priority = index + 1;
            });
            //先頭のサブグループを開く
            this.setEditSubgroup(0);
        },
        //対象サブグループカタログ掲載を追加
        addSubgroupCatalogPage() {
            this.editing_subgroup.catalog_pages.push(new ProductSubgroupCatalogPageRevision());
        },
        //対象サブグループカタログ掲載を削除
        removeSubgroupCatalogPage(index) {
            this.editing_subgroup.catalog_pages.splice(index, 1);
        },

        /**
         * 規格項目設定系
         */

        //規格項目名を表示する
        specLabelDisplay(spec_label_id) {
            let target_spec_label = this.spec_labels.find(spec_label => spec_label.spec_label_id === spec_label_id);

            return target_spec_label ? target_spec_label.label_name : '';
        },
        //規格項目入力タイプ名を表示する
        specLabelInputTypeDisplay(spec_label_id) {
            let target_spec_label = this.spec_labels.find(spec_label => spec_label.spec_label_id === spec_label_id);
            if (!target_spec_label) {
                return null;
            }
            let input_name = InputType.get(target_spec_label.input_type);
            return input_name;
        },
        //規格前置単位名を表示する
        specPrefixDisplay(spec_prefix_id) {
            let target_spec_prefix = this.spec_prefixes.find(spec_prefix => spec_prefix.spec_prefix_id === spec_prefix_id);

            return target_spec_prefix ? target_spec_prefix.prefix_name : '';
        },
        //規格後置単位名を表示する
        specSuffixDisplay(spec_suffix_id) {
            let target_spec_suffix = this.spec_suffixes.find(spec_suffix => spec_suffix.spec_suffix_id === spec_suffix_id);

            return target_spec_suffix ? target_spec_suffix.suffix_name : '';
        },

        //カタログ用規格項目を追加
        addCatalogSpecLabel() {
            if (!this.add_spec_label_id) {
                this.showErrorMessage('入力エラー', '規格項目を選択してください。');
                return;
            }
            //spec_label情報をセット後追加
            let spec_label_revision = new ProductCatalogSpecLabelRevision();

            //マスタより対応するspec_labelを取得
            spec_label_revision.spec_label = this.getMasterSpecLabel(this.add_spec_label_id); //マスタspec_labelをセットしておく（input_typeを後で使用）

            this.editing_subgroup.catalog_spec_labels.push(spec_label_revision); //カタログ用規格項目を追加
            this.add_spec_label_id = null; //リセット
        },
        //カタログ用規格項目を削除
        removeCatalogSpecLabel() {
            if (null === this.target_index) {
                return;
            }
            this.editing_subgroup.catalog_spec_labels.splice(this.target_index, 1);
            this.unsetTargetIndex();
            this.syncCatalogSpecLabel(); //再同期
        },
        //カタログ用規格項目の順番をつける
        setCatalogSpecLabelPriority() {
            this.editing_subgroup.catalog_spec_labels.forEach((catalog_spec_label, index) => {
                catalog_spec_label.priority = index + 1;
            });

            this.syncCatalogSpecLabel();
        },
        // カタログ用規格項目と既に登録済み製品のカタログ用規格値を同期させる
        syncCatalogSpecLabel() {
            for (let product of this.editing_subgroup.products) {
                let originals = JSON.parse(JSON.stringify(product.catalog_spec_values));
                product.catalog_spec_values.splice(0);

                for (let labels_spec_label of this.editing_subgroup.catalog_spec_labels) {
                    //元の入力値を取得しておく（spec_label_idをキーに取得）
                    let original = originals.find(original => {
                        return original.catalog_spec_label.spec_label.spec_label_id === labels_spec_label.spec_label.spec_label_id
                    });
                    //マスタより対応するspec_labelを取得
                    labels_spec_label.spec_label = this.getMasterSpecLabel(labels_spec_label.spec_label.spec_label_id); //マスタspec_labelをセットしておく（input_typeを後で使用）

                    product.catalog_spec_values.push(new ProductCatalogSpecValueRevision({
                        product_catalog_spec_value_revision_id: this.$helper.isNud(original) ? null : original.product_catalog_spec_value_revision_id,
                        catalog_spec_label: labels_spec_label,
                        spec_value: this.$helper.isNud(original) ? null : original.spec_value, //original
                    }));
                }
            }
        },

        //規格図用規格項目を追加
        addStandardSpecLabel() {
            if (!this.add_spec_label_id) {
                this.showErrorMessage('入力エラー', '規格項目を選択してください。');
                return;
            }
            //spec_label情報をセット後追加
            let spec_label_revision = new ProductStandardSpecLabelRevision();

            //マスタより対応するspec_labelを取得
            spec_label_revision.spec_label = this.getMasterSpecLabel(this.add_spec_label_id); //マスタspec_labelをセットしておく（input_typeを後で使用）

            this.editing_subgroup.standard_spec_labels.push(spec_label_revision); //規格図用規格項目を追加
            this.add_spec_label_id = null; //リセット
        },
        //規格図用規格項目を削除
        removeStandardSpecLabel() {
            if (null === this.target_index) {
                return;
            }
            this.editing_subgroup.standard_spec_labels.splice(this.target_index, 1);
            this.unsetTargetIndex();
            this.syncStandardSpecLabel(); //再同期
        },
        //規格図用規格項目の順番をつける
        setStandardSpecLabelPriority() {
            this.editing_subgroup.standard_spec_labels.forEach((standard_spec_label, index) => {
                standard_spec_label.priority = index + 1;
            });

            this.syncStandardSpecLabel();
        },
        // 規格図用規格項目と既に登録済み製品の規格図用規格値を同期させる
        syncStandardSpecLabel() {
            for (let product of this.editing_subgroup.products) {
                let originals = JSON.parse(JSON.stringify(product.standard_spec_values));

                product.standard_spec_values.splice(0);
                for (let labels_spec_label of this.editing_subgroup.standard_spec_labels) {
                    //元の入力値を取得しておく（spec_label_idをキーに取得）
                    let original = originals.find(original => {
                        return original.standard_spec_label.spec_label.spec_label_id === labels_spec_label.spec_label.spec_label_id
                    });
                    //マスタより対応するspec_labelを取得
                    labels_spec_label.spec_label = this.getMasterSpecLabel(labels_spec_label.spec_label.spec_label_id); //マスタspec_labelをセットしておく（input_typeを後で使用）

                    product.standard_spec_values.push(new ProductStandardSpecValueRevision({
                        product_standard_spec_value_revision_id: this.$helper.isNud(original) ? null : original.product_standard_spec_value_revision_id,
                        standard_spec_label: labels_spec_label,
                        spec_value: this.$helper.isNud(original) ? null : original.spec_value, //original
                    }));
                }
            }
        },

        //製作図用規格項目を追加
        addProductionSpecLabel() {
            if (!this.add_spec_label_id) {
                this.showErrorMessage('入力エラー', '規格項目を選択してください。');
                return;
            }
            //spec_label_idをセット後追加
            let spec_label_revision = new ProductProductionSpecLabelRevision();

            //マスタより対応するspec_labelを取得
            spec_label_revision.spec_label = this.getMasterSpecLabel(this.add_spec_label_id) //マスタspec_labelをセットしておく（input_typeを後で使用）

            this.editing_subgroup.production_spec_labels.push(spec_label_revision); //製作図用規格項目を追加
            this.add_spec_label_id = null; //リセット
        },
        //製作図用規格項目を削除
        removeProductionSpecLabel() {
            if (null === this.target_index) {
                return;
            }
            this.editing_subgroup.production_spec_labels.splice(this.target_index, 1);
            this.unsetTargetIndex();
            this.syncProductionSpecLabel(); //再同期
        },
        //製作図用規格項目の順番をつける
        setProductionSpecLabelPriority() {
            this.editing_subgroup.production_spec_labels.forEach((production_spec_label, index) => {
                production_spec_label.priority = index + 1;
            });

            //続いて同期へ
            this.syncProductionSpecLabel();
        },

        // 製作図用規格項目と既に登録済み製品の製作図用規格値を同期させる
        syncProductionSpecLabel() {
            for (let product of this.editing_subgroup.products) {
                let originals = JSON.parse(JSON.stringify(product.production_spec_values));

                product.production_spec_values.splice(0);

                for (let labels_spec_label of this.editing_subgroup.production_spec_labels) {
                    //元の入力値を取得しておく（spec_label_idをキーに取得）
                    let original = originals.find(original => {
                        return original.production_spec_label.spec_label.spec_label_id === labels_spec_label.spec_label.spec_label_id
                    });
                    //マスタより対応するspec_labelを取得
                    labels_spec_label.spec_label = this.getMasterSpecLabel(labels_spec_label.spec_label.spec_label_id); //マスタspec_labelをセットしておく（input_typeを後で使用）

                    product.production_spec_values.push(new ProductProductionSpecValueRevision({
                        product_production_spec_value_revision_id: this.$helper.isNud(original) ? null : original.product_production_spec_value_revision_id,
                        production_spec_label: labels_spec_label,
                        spec_value: this.$helper.isNud(original) ? null : original.spec_value, //original
                    }));
                }
            }
        },

        //マスタより対応するspec_labelを取得
        getMasterSpecLabel(spec_label_id) {
            let master_spec_label = this.spec_labels.find(master_spec_label => {
                return master_spec_label.spec_label_id === spec_label_id
            });

            return master_spec_label;
        },

        /**
         * 製品系
         */

        //製品を追加
        addProduct() {
            let errors = this.validateProduct(this.additional_product);

            //エラーがある場合追加させない
            if (errors.length) {
                this.showErrorMessage('入力エラー', errors.join("\n"));
                return;
            }

            // カタログ図用規格項目を追加
            for (let catalog_spec_label of this.editing_subgroup.catalog_spec_labels) {
                let additional_catalog_spec_value = new ProductCatalogSpecValueRevision();
                additional_catalog_spec_value.catalog_spec_label = catalog_spec_label;
                this.additional_product.catalog_spec_values.push(additional_catalog_spec_value);
            }
            // 規格図用規格項目を追加
            for (let standard_spec_label of this.editing_subgroup.standard_spec_labels) {
                let additional_standard_spec_value = new ProductStandardSpecValueRevision();
                additional_standard_spec_value.standard_spec_label = standard_spec_label;
                this.additional_product.standard_spec_values.push(additional_standard_spec_value);
            }
            // 製作図用規格項目を追加
            for (let production_spec_label of this.editing_subgroup.production_spec_labels) {
                let additional_production_spec_value = new ProductProductionSpecValueRevision();
                additional_production_spec_value.production_spec_label = production_spec_label;
                this.additional_product.production_spec_values.push(additional_production_spec_value);
            }
            // 対象製品サブグループに追加
            this.editing_subgroup.products.push(this.additional_product);
            //初期化
            this.additional_product = new ProductRevision();

            this.closeAddProductModal(); //モーダルクローズ
        },
        //製品を削除
        removeProduct() {
            if (null === this.target_index) {
                return;
            }
            this.editing_subgroup.products.splice(this.target_index, 1);
            this.unsetTargetIndex();
        },
        //製品の順番をつける
        setProductPriority() {
            this.product_group_revision.subgroups.forEach((subgroup) => {
                subgroup.products.forEach((product, index) => {
                    product.priority = index + 1;
                });
            });
        },

        //編集する製品をモーダルにセット
        setEditProduct(product, index) {
            this.editing_product = product;
            this.setTargetIndex(index); //モーダルのキャンセルボタン押下処理に利用
        },
        //元の製品データを保持（モーダルOPEN時）
        keepOld() {
            this.old_product = {}; //初期化
            //元のオブジェクトを保持（json化して参照渡し回避）
            this.old_product = new ProductRevision(JSON.parse(JSON.stringify(this.editing_product)));
        },
        //元の製品データに戻す（キャンセルボタンクリック時）
        restoreOld() {
            if (null === this.target_index) {
                return;
            }
            //元の情報に置き換え（target_indexは、モーダルOPEN時にSETしている）
            this.editing_subgroup.products.splice(this.target_index, 1 , this.old_product);
            //規格項目の再sync
            this.syncCatalogSpecLabel();
            this.syncStandardSpecLabel();
            this.syncProductionSpecLabel();
            //target_index初期化
            this.unsetTargetIndex();
        },

        //製品サブグループカタログ図リビジョンにレコードが登録されている場合はその1件目の画像を、登録されていない場合は、親の製品グループのカタログ図リビジョンの1件目の画像を表示する。
        setModalProductCatalogDrawing() {
            if (
                this.product_group_revision.subgroups.length &&
                this.editing_subgroup.catalog_drawings.length
            ) {
                this.modal_catalog_drawing = this.editing_subgroup.catalog_drawings[0].file_url + '?access_token=' + this.$store.state.auth.access_token;

            } else if (this.product_group_revision.catalog_drawings.length) {
                this.modal_catalog_drawing = this.product_group_revision.catalog_drawings[0].file_url + '?access_token=' + this.$store.state.auth.access_token;

            } else {
                this.modal_catalog_drawing = '';
            }
        },
        //製品サブグループ規格図リビジョンにレコードが登録されている場合はその1件目の画像を、登録されていない場合は、製品グループ規格図リビジョンの1件目の画像を表示する。
        setModalProductStandardDrawing() {
            if (
                this.product_group_revision.subgroups.length &&
                this.editing_subgroup.standard_drawings.length
            ) {
                this.modal_standard_drawing = this.editing_subgroup.standard_drawings[0].file_url + '?access_token=' + this.$store.state.auth.access_token;

            } else if (this.product_group_revision.standard_drawings.length) {
                this.modal_standard_drawing = this.product_group_revision.standard_drawings[0].file_url + '?access_token=' + this.$store.state.auth.access_token;

            } else {
                this.modal_standard_drawing = '';
            }
        },
        //モーダルオープン時に各製品の紐づく全ての商社データを、なければ商社マスタから作成し追加
        setProductRetailers() {
            //持っている商社データを追加
            this.retailers.forEach((retailer) => {
                let having_retailers = [];
                having_retailers = this.editing_product.retailers.find( function(product_retailer) {
                    return product_retailer.retailer.retailer_id === retailer.retailer_id;
                });
                if (!having_retailers || having_retailers.length === 0) {
                    let new_product_retailer = new ProductRetailerRevision();
                    new_product_retailer.retailer = new Retailer(retailer);
                    this.editing_product.retailers.push(new_product_retailer);
                }
            });
        },
        //掛率記号の変更で掛率をSET
        setWholesaleRate() {
            let wholesale_rate = this.wholesale_rates.find(wholesale_rate => wholesale_rate.wholesale_rate_id === this.selected_wholesale_rate_id);

            if (this.$helper.isNud(wholesale_rate)) {
                wholesale_rate = new WholesaleRate();
            }

            this.editing_product.wholesale_rate = wholesale_rate;
        },
        //商社別製品の商社別掛率が変更されると卸価を自動で入力する
        setRetailerPrice(product_retailer) {
            let regular_price = parseInt(this.editing_product.regular_price, 10);

            if (!product_retailer.retailer_rate) {
                return regular_price;
            }

            let rate = product_retailer.retailer_rate;

            product_retailer.retailer_price = Math.floor(regular_price * (rate / 100)); //切り捨て
        },
        
        //構成品: 製品マスタより製品コードで検索（自身以外）
        searchComponent(page = 1) {
            this.condition.my_product_id = this.editing_product.product_id, //自身のID
            this.condition.page = page;
            //表示データ初期化
            this.child_products.splice(0);

            this.component_loading++;

            this.$http.get(`/product`, {
                params: this.condition
            })
            .then(response => {
                for (let row of response.data.data) {
                    this.child_products.push(new Product(row));
                }
                //ページネーション用
                this.paginator = response.data.meta;
            })
            .finally(() => {
                this.component_loading--;
                this.searched_component = true;
            });
        },
        //構成品を追加
        addProductComponent(child_product) {
            //重複を回避
            if (this.editing_product.components.length > 0) {
                let duplicated = this.editing_product.components.find(component => {
                    return component.child_product.joined_product_code === child_product.joined_product_code;
                });
                if (duplicated) {
                    this.showErrorMessage('構成品が重複しています');
                    return;
                }
            }
            //構成品配列に追加
            let component = new ProductComponentRevision();
            component.child_product = child_product;
            this.editing_product.components.push(component);

            //検索内容リセット
            this.condition.product_code = null;
            this.searched_component = false;
            this.child_products.splice(0);

            this.closeSearchComponentModal(); //検索モーダルクローズ
        },
        //構成品を削除
        removeProductComponent(index) {
            this.editing_product.components.splice(index, 1);
        },

        /**
         * [モーダル] 製品グループ
         */
        //規格変更履歴モーダル
        openWorkflowModal() {
            this.workflowModal = true;
        },
        closeWorkflowModal() {
            let errors = this.validateWorkflow();
            //エラーがある場合完了させない
            if (errors.length) {
                this.showErrorMessage('入力エラー', errors.join("\n"));
                return;
            }
            this.workflowModal = false;
        },

        /**
         * [モーダル] サブグループ
         */
        //カタログ用 規格項目設定モーダル
        openCatalogSpecModal() {
            this.add_spec_label_id = null;
            this.catalogSpecModal = true;
        },
        closeCatalogSpecModal() {
            //＋順番・バリデート・同期
            let errors = this.validateCatalogSpecLabel();

            if (errors.length) {
                this.showErrorMessage('入力エラー', errors.join("\n"));
                return;
            }

            //順番と同期へ
            this.setCatalogSpecLabelPriority();
            this.catalogSpecModal = false; //クローズモーダル
        },
        //規格図用 規格項目設定モーダル
        openStandardSpecModal() {
            this.add_spec_label_id = null;
            this.standardSpecModal = true;
        },
        closeStandardSpecModal() {
            //＋順番・バリデート・同期
            let errors = this.validateStandardSpecLabel();

            if (errors.length) {
                this.showErrorMessage('入力エラー', errors.join("\n"));
                return;
            }

            //順番と同期へ
            this.setStandardSpecLabelPriority();
            this.standardSpecModal = false; //クローズモーダル
        },
        //製作図用 規格項目設定モーダル
        openProductionSpecModal() {
            //製作図情報編集権限がなければ開かせない
            if (!this.$store.getters['auth/canEditProduction']()) {
                return;
            }
            this.add_spec_label_id = null;
            this.productionSpecModal = true;
        },
        closeProductionSpecModal() {
            //＋順番・バリデート・同期
            let errors = this.validateProductionSpecLabel();
            if (errors.length) {
                this.showErrorMessage('入力エラー', errors.join("\n"));
                return;
            }

            //順番と同期へ
            this.setProductionSpecLabelPriority();
            this.productionSpecModal = false; //クローズモーダル

        },
        //製品追加モーダル
        openAddProductModal() {
            this.addProductModal = true; //モーダルオープン
        },
        closeAddProductModal() {
            this.addProductModal = false; //モーダルクローズ
        },
        //サブグループ並び替え/削除 モーダル
        openSortSubgroupModal() {
            this.sortSubgroupModal = true; //モーダルオープン
        },
        closeSortSubgroupModal() {
            this.sortSubgroupModal = false; //モーダルクローズ
        },

        /**
         * [モーダル] 製品
         */

        //基本情報モーダル
        openProductBasicModal() {
            this.keepOld(); //元のデータ保持
            this.productBasicModal = true; //モーダルオープン
        },
        closeProductBasicModal(product) {
            //バリデートする
            let errors = this.validateProduct(product);

            //エラーがある場合閉じさせない
            if (errors.length) {
                this.showErrorMessage('入力エラー', errors.join("\n"));
                return;
            }
            this.productBasicModal = false; //モーダルクローズ
        },
        cancelProductBasicModal () {
            this.restoreOld(); //元に戻す
            this.productBasicModal = false; //モーダルクローズ
        },
        //カタログ掲載情報モーダル
        openProductCatalogPageModal() {
            this.keepOld(); //元のデータ保持
            this.productCatalogPageModal = true; //モーダルオープン
        },
        closeProductCatalogPageModal(product) {
            //バリデートする
            let errors = this.validateProductCatalog(product);

            //エラーがある場合閉じさせない
            if (errors.length) {
                this.showErrorMessage('入力エラー', errors.join("\n"));
                return;
            }

            this.productCatalogPageModal = false; //モーダルクローズ
        },
        cancelProductCatalogPageModal () {
            this.restoreOld(); //元に戻す
            this.productCatalogPageModal = false; //モーダルクローズ
        },
        //カタログ図情報モーダル
        openProductCatalogDrawingModal() {
            this.keepOld(); //元のデータ保持
            this.productCatalogDrawingModal = true; //モーダルオープン
        },
        closeProductCatalogDrawingModal() {
            this.productCatalogDrawingModal = false; //モーダルクローズ
        },
        cancelProductCatalogDrawingModal () {
            this.restoreOld(); //元に戻す
            this.productCatalogDrawingModal = false; //モーダルクローズ
        },
        //規格図情報モーダル
        openProductStandardDrawingModal() {
            this.keepOld(); //元のデータ保持
            this.productStandardDrawingModal = true; //モーダルオープン
        },
        closeProductStandardDrawingModal() {
            this.productStandardDrawingModal = false; //モーダルクローズ
        },
        cancelProductStandardDrawingModal () {
            this.restoreOld(); //元に戻す
            this.productStandardDrawingModal = false; //モーダルクローズ
        },
        //カタログ図 / 規格図情報 並べて編集モーダル
        openProductCatalogStandardModal() {
            this.setModalProductStandardDrawing(); //画像セット
            this.setModalProductCatalogDrawing(); //画像セット
            this.syncStandardSpecLabel(); //規格項目を再同期
            this.syncCatalogSpecLabel(); //規格項目を再同期
            this.closeProductCatalogDrawingModal(); //カタログ図情報モーダルを開いている場合は閉じる
            this.closeProductStandardDrawingModal(); //規格図情報モーダルを開いている場合は閉じる

            this.keepOld(); //元のデータ保持
            this.productCatalogStandardModal = true; //モーダルオープン
        },
        closeProductCatalogStandardModal() {
            this.productCatalogStandardModal = false; //モーダルクローズ
        },
        cancelProductCatalogStandardModal() {
            this.restoreOld(); //元に戻す
            this.productCatalogStandardModal = false; //モーダルクローズ
        },
        //製作情報モーダル
        openProductProductionModal() {
            //製作図情報編集権限がなければ開かせない
            if (!this.$store.getters['auth/canEditProduction']()) {
                return;
            }
            this.keepOld(); //元のデータ保持
            this.productProductionModal = true; //モーダルオープン
        },
        closeProductProductionModal(product) {
            //バリデートする
            let errors = this.validateProductProduction(product);

            //エラーがある場合閉じさせない
            if (errors.length) {
                this.showErrorMessage('入力エラー', errors.join("\n"));
                return;
            }

            this.productProductionModal = false; //モーダルクローズ
        },
        cancelProductProductionModal() {
            this.restoreOld(); //元に戻す
            this.productProductionModal = false; //モーダルクローズ
        },
        //価格・掛率情報モーダル
        openProductPriceRateModal() {
            this.keepOld(); //元のデータ保持
            this.setProductRetailers();
            //selectBoxにIDをセット
            this.selected_wholesale_rate_id = this.editing_product.wholesale_rate.wholesale_rate_id;
            this.productPriceRateModal = true; //モーダルオープン
        },
        closeProductPriceRateModal() {
            //バリデートする
            let errors = this.validateProductPriceRateModal();

            //エラーがある場合閉じさせない
            if (errors.length) {
                this.showErrorMessage('入力エラー', errors.join("\n"));
                return;
            }

            this.productPriceRateModal = false; //モーダルクローズ
        },
        cancelProductPriceRateModal() {
            this.restoreOld(); //元に戻す
            this.productPriceRateModal = false; //モーダルクローズ
        },
        //構成品情報モーダル
        openProductComponentModal() {
            this.keepOld(); //元のデータ保持
            this.productComponentModal = true; //モーダルオープン
        },
        closeProductComponentModal(product) {
            //バリデートする
            let errors = this.validateProductComponent(product);

            //エラーがある場合閉じさせない
            if (errors.length) {
                this.showErrorMessage('入力エラー', errors.join("\n"));
                return;
            }

            product.components.forEach((component, index) => {
                component.sequence = index + 1;
            });

            this.productComponentModal = false; //モーダルクローズ
        },
        cancelProductComponentModal() {
            this.restoreOld(); //元に戻す
            this.productComponentModal = false; //モーダルクローズ
        },
        //構成品検索モーダル
        openSearchComponentModal() {
            this.searchComponentModal = true; //モーダルオープン
        },
        closeSearchComponentModal() {
            this.searchComponentModal = false; //モーダルクローズ
        },

        /**
         * バリデート
         */

        //保存時 製品グループバリデート
        validateSave() {
            // 未入力チェック
            let errors = [];

            //型・未入力チェック
            if(!this.product_group_revision.public_type) {
                errors.push('公開区分が未入力です');
            }
            if(!this.product_group_revision.availability) {
                errors.push('ステータスが未入力です');
            }
            //製品グループ情報
            if(!this.product_group_revision.product_group_code) {
                errors.push('[製品グループ情報]製品グループコードが未入力です');
            }
            if(this.product_group_revision.product_group_code && !this.checkHalfEnNumPlus(this.product_group_revision.product_group_code)) {
                errors.push('[製品グループ情報]製品グループコードは半角英数記号で入力してください');
            }
            if(!this.product_group_revision.product_group_name) {
                errors.push('[製品グループ情報]製品グループ名が未入力です');
            }
            this.product_group_revision.images.forEach((image, index) => {
                if (!image.file_label) {
                    errors.push(`[画像]${index + 1}つ目の画像名が未入力です`);
                }
            });
            this.product_group_revision.catalog_pages.forEach((catalog_page, index) => {
                if (!catalog_page.catalog.catalog_id) {
                    errors.push(`[カタログ]${index + 1}行目のカタログが選択されていません`);
                }
                if (catalog_page.catalog_url && !this.checkHalfEnNumPlus(catalog_page.catalog_url)) {
                    errors.push(`[カタログ]${index + 1}行目のURLは半角英数記号で入力してください`);
                }
            });
            this.product_group_revision.catalog_drawings.forEach((image, index) => {
                if (!image.file_label) {
                    errors.push(`[カタログ図]${index + 1}つ目の画像名が未入力です`);
                }
            });
            this.product_group_revision.standard_drawings.forEach((image, index) => {
                if (!image.file_label) {
                    errors.push(`[規格図]${index + 1}つ目の画像名が未入力です`);
                }
            });
            this.product_group_revision.flyers.forEach((image, index) => {
                if (!image.file_label) {
                    errors.push(`[チラシ]${index + 1}つ目の画像名が未入力です`);
                }
            });
            this.product_group_revision.movies.forEach((movie, index) => {
                if (!movie.movie_name) {
                    errors.push(`[動画]${index + 1}行目の動画名が未入力です`);
                }
                if (!movie.movie_url) {
                    errors.push(`[動画]${index + 1}行目の動画のURLが未入力です`);
                }
                if (movie.movie_url && !this.checkHalfEnNumPlus(movie.movie_url)) {
                    errors.push(`[動画]${index + 1}行目の動画URLは半角英数記号で入力してください`);
                }
            });
            // 製品サブグループ情報
            this.product_group_revision.subgroups.forEach((subgroup, index) => {
                if (!subgroup.product_subgroup_name) {
                    errors.push(`[サブグループ - ${index + 1}つ目のサブグループ名が未入力です`);
                }
                let subgroup_name = subgroup.product_subgroup_name;
                subgroup.images.forEach((image, index) => {
                    if (!image.file_label) {
                        errors.push(`[サブグループ - ${subgroup_name}][画像]${index + 1}つ目の画像名が未入力です`);
                    }
                });
                subgroup.catalog_pages.forEach((catalog_page, index) => {
                    if (!catalog_page.catalog.catalog_id) {
                        errors.push(`[サブグループ - ${subgroup_name}][カタログ]${index + 1}行目のカタログが選択されていません`);
                    }
                    if (catalog_page.catalog_url && !this.checkHalfEnNumPlus(catalog_page.catalog_url)) {
                        errors.push(`[サブグループ - ${subgroup_name}][カタログ]${index + 1}行目のURLは半角英数記号で入力してください`);
                    }
                });
                subgroup.catalog_drawings.forEach((drawing, index) => {
                    if (!drawing.file_label) {
                        errors.push(`[サブグループ - ${subgroup_name}][カタログ図]${index + 1}つ目のカタログ図名が未入力です`);
                    }
                });
                subgroup.standard_drawings.forEach((drawing, index) => {
                    if (!drawing.file_label) {
                        errors.push(`[サブグループ - ${subgroup_name}][規格図]${index + 1}つ目の規格図名が未入力です`);
                    }
                });
                subgroup.production_drawings.forEach((drawing, index) => {
                    if (!drawing.file_label) {
                        errors.push(`[サブグループ - ${subgroup_name}][製作図]${index + 1}つ目の製作図名が未入力です`);
                    }
                });
            });

            return errors;
        },
        //規格変更履歴完了時バリデート
        validateWorkflow() {
            // 未入力チェック
            let errors = [];
            this.product_group_revision.workflows.forEach((workflow, index) => {
                if (!workflow.workflow_date) {
                    errors.push(`${index + 1}行目の申請日付が未入力です`);
                }
                if (!workflow.workflow_number) {
                    errors.push(`${index + 1}行目の規格変更管理番号が未入力です`);
                }
                if (!workflow.approved_status) {
                    errors.push(`${index + 1}行目の完了状況が未入力です`);
                }
            });

            return errors;
        },
        //カタログ図用規格項目バリデート
        validateCatalogSpecLabel() {
            let errors = [];
            // 重複チェック
            let spec_label_ids = this.editing_subgroup.catalog_spec_labels.map(label => {
                return label.spec_label.spec_label_id;
            });
            let set_ids = new Set(spec_label_ids);
            if (set_ids.size !== spec_label_ids.length) {
                errors.push(`重複している規格項目があります`);
            }

            return errors;
        },
        //規格図用規格項目バリデート
        validateStandardSpecLabel() {
            let errors = [];
            // 重複チェック
            let spec_label_ids = this.editing_subgroup.standard_spec_labels.map(label => {
                return label.spec_label.spec_label_id;
            });
            let set_ids = new Set(spec_label_ids);
            if (set_ids.size !== spec_label_ids.length) {
                errors.push(`重複している規格項目があります`);
            }

            return errors;
        },
        //製品をバリデート（追加時・編集時）
        validateProduct(product) {
            // 未入力・型チェック
            let errors = [];
            if (!product.product_code) {
                errors.push(`製品コードが未入力です`);
            }
            if (!product.public_type) {
                errors.push(`公開区分が未入力です`);
            }
            if (!product.availability) {
                errors.push(`ステータスが未入力です`);
            }
            if(product.product_code && !this.checkHalfEnNumPlus(product.product_code)) {
                errors.push('製品コードは半角英数記号で入力してください');
            }
            if(product.regular_price && !this.checkPositiveNum(product.regular_price)) {
                errors.push('販売価格は整数で入力してください');
            }
            if(product.parts_number && !this.checkHalfEnNumPlus(product.parts_number)) {
                errors.push('Parts No.は半角英数記号で入力してください');
            }

            return errors;
        },
        //規格図用規格項目バリデート
        validateProductionSpecLabel() {
            let errors = [];
            // 重複チェック
            let spec_label_ids = this.editing_subgroup.production_spec_labels.map(label => {
                return label.spec_label.spec_label_id;
            });
            let set_ids = new Set(spec_label_ids);
            if (set_ids.size !== spec_label_ids.length) {
                errors.push(`重複している規格項目があります`);
            }

            return errors;
        },
        //カタログ掲載情報バリデート
        validateProductCatalog(product) {
            let errors = [];

            product.images.forEach((image, index) => {
                if (!image.file_label) {
                    errors.push(`${index + 1}つ目の画像名が未入力です`);
                }
            });

            return errors;
        },
        //製作情報バリデート
        validateProductProduction(product) {
            let errors = [];

            product.production_drawings.forEach((image, index) => {
                if (!image.file_label) {
                    errors.push(`${index + 1}つ目の画像名が未入力です`);
                }
            });

            return errors;
        },
        // 価格・掛率情報バリデート
        validateProductPriceRateModal() {
            let errors = [];

            this.editing_product.retailers.forEach((retailer) => {
                if(retailer.retailer_rate && !this.checkPositiveNum(retailer.retailer_rate)) {
                    errors.push(retailer.retailer.retailer_name + 'の商社掛率は整数で入力してください');
                }
                if(retailer.retailer_price && !this.checkPositiveNum(retailer.retailer_price)) {
                    errors.push(retailer.retailer.retailer_name + 'の販売価格は整数で入力してください');
                }
            });

            return errors;
        },
        //構成品情報バリデート
        validateProductComponent(product) {
            let errors = [];
            //未入力チェック
            product.components.forEach((component, index) => {
                // if (!component.sequence) {
                //     errors.push(`${index + 1}行目の番号が未入力です`);
                // }
                if (!component.component_count) {
                    errors.push(`${index + 1}行目の入数が未入力です`);
                }
            });

            return errors;
        },

        //バリデート判定関数
        //半角英数（空文字OK）
        checkHalfEnNum: function(data){
            let re = /^[a-zA-Z0-9]*$/
            return re.test(data);
        },
        //半角整数（空文字OK）
        checkPositiveNum: function(data){
            let re = /^[0-9]*$/
            return re.test(data);
        },
        //半角英数記号（空文字OK）
        checkHalfEnNumPlus: function(data){
            let re = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/;
            return re.test(data);
        },

        /**
         * [排他処理]
         */

        // 1分ごとに排他ロック継続処理
        setHeartBeat() {
            this.heartbeat = setTimeout(() => {
                this.$http.get(`/product-group/${this.product_group_id}/heartbeat`)
                .then(response => {
                    let result = response.data.result;
                    let message = response.data.message;

                    if (result) {
                        this.setHeartBeat();

                    } else {
                        this.showErrorMessage(message);
                        this.$router.push({name: 'ProductGroupView', id: this.product_group_id});
                        return;
                    }
                });
            }, 60000);
        },
        //排他ロックチェック継続処理停止
        clearHeartBeat() {
            clearTimeout(this.heartbeat);
        },
        // 排他ロック強制開放
        releaseEditRock() {
            this.$http.get(`/product-group/${this.product_group_id}/release`)
            .then(() => {

            });
        },
    },
    watch: {
        // product_group_revision オブジェクト内のすべての変更を監視する（deep: ネストした先も監視）
        product_group_revision: {
            handler () {
                this.is_saved = false;
            },
            deep: true
        },
    },

}
</script>

<style scoped>
    .link-parent {position: relative;}
    .link-child {position: absolute; right: .5rem; bottom: .5rem;}
    .small-icon {padding: 0 .3rem; background-color: #fff; color: #0d6efd; border-color: #fff;}
    .small-icon i {font-size: .8rem;}
    .handle {cursor: pointer;}
    .accordion-button:not(.collapsed) {background-color: #fff;}
    .accordion-button::after {margin-left: .5rem; margin-right: auto;}
    .rotate:before {transform: scale(-1, 1);}
    /* .product-code-id {display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;} */
    .product-table td {word-break: break-all;}
    .sid-small {font-size: .65rem;}
</style>
