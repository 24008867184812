<template>
    <button :type="type" class="btn btn-sm" :class="[optional_class, color]">
        <i class="fs-5 bi" :class="icon"></i><small>{{ text }}</small>
        <slot/>
    </button>
</template>

<script>
export default {
    name: 'ButtonCancelClose',
    props: {
        text: {
            type: String,
            default: 'キャンセル',
        },
        type: {
            type: String,
            default: 'button',
        },
        optional_class: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: 'btn-light'
        },
        icon: {
            type: String,
            default: 'bi-x'
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
