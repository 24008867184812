// import { isNud } from '@/utilities/typing';
import ProductGroupImageRevision from '@/models/entities/product-group-image-revision';
import ProductGroupCatalogDrawingRevision from '@/models/entities/product-group-catalog-drawing-revision';
import ProductGroupStandardDrawingRevision from '@/models/entities/product-group-standard-drawing-revision';
import ProductGroupFlyerRevision from '@/models/entities/product-group-flyer-revision';
import ProductSubgroupImageRevision from '@/models/entities/product-subgroup-image-revision';
import ProductSubgroupCatalogDrawingRevision from '@/models/entities/product-subgroup-catalog-drawing-revision';
import ProductSubgroupStandardDrawingRevision from '@/models/entities/product-subgroup-standard-drawing-revision';
import ProductSubgroupProductionDrawingRevision from '@/models/entities/product-subgroup-production-drawing-revision';
import ProductImageRevision from '@/models/entities/product-image-revision';
import ProductProductionDrawingRevision from '@/models/entities/product-production-drawing-revision';

/**
 * アップロード関連サービス
 */
class UploadService
{
    static PRODUCT_GROUP_IMAGE = 'product-group/image';
    static PRODUCT_GROUP_CATALOG = 'product-group/catalog-drawing';
    static PRODUCT_GROUP_STANDARD = 'product-group/standard-drawing';
    static PRODUCT_GROUP_FLYER = 'product-group/flyer';
    static SUBGROUP_IMAGE = 'subgroup/image';
    static SUBGROUP_CATALOG = 'subgroup/catalog-drawing';
    static SUBGROUP_STANDARD = 'subgroup/standard-drawing';
    static SUBGROUP_PRODUCTION = 'subgroup/production-drawing';
    static PRODUCT_IMAGE = 'product/image';
    static PRODUCT_PRODUCTION = 'product/production-drawing';


    static setNewEntity(upload_dir, object = null) {
        if (upload_dir === this.PRODUCT_GROUP_IMAGE) {
            return new ProductGroupImageRevision(object);
        }
        if (upload_dir === this.PRODUCT_GROUP_CATALOG) {
            return new ProductGroupCatalogDrawingRevision(object);
        }
        if (upload_dir === this.PRODUCT_GROUP_STANDARD) {
            return new ProductGroupStandardDrawingRevision(object);
        }
        if (upload_dir === this.PRODUCT_GROUP_FLYER) {
            return new ProductGroupFlyerRevision(object);
        }
        if (upload_dir === this.SUBGROUP_IMAGE) {
            return new ProductSubgroupImageRevision(object);
        }
        if (upload_dir === this.SUBGROUP_CATALOG) {
            return new ProductSubgroupCatalogDrawingRevision(object);
        }
        if (upload_dir === this.SUBGROUP_STANDARD) {
            return new ProductSubgroupStandardDrawingRevision(object);
        }
        if (upload_dir === this.SUBGROUP_PRODUCTION) {
            return new ProductSubgroupProductionDrawingRevision(object);
        }
        if (upload_dir === this.PRODUCT_IMAGE) {
            return new ProductImageRevision(object);
        }
        if (upload_dir === this.PRODUCT_PRODUCTION) {
            return new ProductProductionDrawingRevision(object);
        }
    }

    static isMatchedType(file) {
        if (file instanceof ProductGroupImageRevision) {
            return true;
        }
        if (file instanceof ProductGroupCatalogDrawingRevision) {
            return true;
        }
        if (file instanceof ProductGroupStandardDrawingRevision) {
            return true;
        }
        if (file instanceof ProductGroupFlyerRevision) {
            return true;
        }
        if (file instanceof ProductSubgroupImageRevision) {
            return true;
        }
        if (file instanceof ProductSubgroupCatalogDrawingRevision) {
            return true;
        }
        if (file instanceof ProductSubgroupStandardDrawingRevision) {
            return true;
        }
        if (file instanceof ProductSubgroupProductionDrawingRevision) {
            return true;
        }
        if (file instanceof ProductImageRevision) {
            return true;
        }
        if (file instanceof ProductProductionDrawingRevision) {
            return true;
        }

        return false;
    }
}

export default UploadService;