<template>
    <div class="d-flex">
        <template v-if="files.length">
            <draggable
                v-model="files"
                item-key="file"
                handle=".handle"
                class="form-upload-list"
                @end="onMovePriority"
            >
                <template #item="{element, index}">
                    <div class="form-upload-item" :class="{'handle': is_editable}">
                        <figure class="form-upload-image mb-1">
                            <img
                                :src="element.thumbnail_url + '?access_token=' + this.$store.state.auth.access_token"
                                :alt="element.file_label"
                            >
                        </figure>
                        <form-input
                            v-model="element.file_label"
                            maxlength="191"
                            required="required"
                            :disabled="!is_editable"
                        ></form-input>
                        <button
                            v-if="is_editable"
                            type="button"
                            class="form-uploader-remove"
                            @click="remove(index)"
                        ><i class="bi bi-trash"></i></button>
                    </div>
                </template>
            </draggable>
        </template>
        <div class="form-upload-button bg-light border" v-if="is_editable">
            <label class="d-flex justify-content-center align-items-center pointer" style="height: 100%;" v-if="is_editable">
                <input type="file" style="display: none;" multiple="multiple" @change="upload">
                <div><i class="bi bi-upload"></i> {{ label }}</div>
            </label>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import UploadService from '@/models/services/upload-service';
import FormInput from '@/components/forms/FormInput';

export default {
    name: 'FormUploadMultiple',
    components: {
        draggable,
        FormInput,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            //ファイルオブジェクトの配列 (通常はmodelValueのコピー)
            files: [],
        }
    },
    props: {
        modelValue: {
            type: Array,
        },
        upload_dir: {
            type: String,
        },
        label: {
            type: String,
            default: 'アップロード'
        },
        is_editable: {
            type: Boolean,
            default: true
        }
    },
    emits: [
        'update:modelValue'
    ],
    mounted() {
        this.setModel();
    },
    watch: {
        modelValue: function () {
            this.setModel();
        },
    },
    methods: {
        //外から値が変わったとき
        setModel() {
            if (!UploadService.setNewEntity(this.upload_dir)) {
                throw '不正なアップロード先が指定されています';
            }
            //元から入っているファイルたちは消す
            //this.files.splice(0);
            //↑これだと、この瞬間、何故か modelValue まで空になる。謎い。
            this.files = [];

            if (this.modelValue.length) {
                for (let row of this.modelValue) {
                    if (!UploadService.isMatchedType(row)) {
                        throw 'セットされたmodelValueがUpload_dirと一致していません';

                    } else if (this.$helper.isNud(row)) {
                        this.files.push(UploadService.setNewEntity(this.upload_dir));

                    } else {
                        this.files.push(row);
                    }
                }
            }

        },
        //ファイルアップロード → 成功したらemit
        upload(event) {
            //ファイルがなければ終了
            if (event.target.files.length === 0) {
                return;
            }
            
            let formData = new FormData()
            let uploading_files = event.target.files;

            for (let uploading_file of uploading_files) {
                formData.append('file[]', uploading_file);
            }

            this.startScreenLoading();
            let headers = {
                'content-type': 'multipart/form-data'
            };
            this.$http.post(`/upload/${this.upload_dir}/revision`, formData, {headers})
            .then(response => {
                for (let row of response.data) {
                    let file = UploadService.setNewEntity(this.upload_dir, row);
                    this.files.push(file);
                }
                this.$emit('update:modelValue', this.files);
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //並び替え
        onMovePriority: function(){ //引数に「event」を入れれば event 取得可能
            this.$emit('update:modelValue', this.files);
        },
        //ファイルを外す
        remove(index) {
            this.files.splice(index, 1);
            this.$emit('update:modelValue', this.files);
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-upload-list {flex-basis: 80%; display: flex; flex-wrap: wrap;}
.form-upload-item {position: relative; width: 25%; padding: 0.5rem;}
.form-upload-image {position: relative; width: 100%; padding-top: 100%;}
.form-upload-image img {position: absolute; left: 0; top: 0; width: 100%; height: 100%; object-fit: cover;}
.form-upload-button {flex-basis: 20%; flex-grow: 1; min-height: 100px;}
.form-uploader-remove {position: absolute; right: -0.5rem; top: -0.5rem; border: none; background: #EEE; border-radius: 50%;}
.pointer {cursor: pointer}
</style>
